import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    ref: "remoteSelect",
    filterable: "",
    remote: "",
    placeholder: _ctx.placeholder,
    "remote-method": _ctx.remoteMethodFn,
    loading: _ctx.loading,
    clearable: _ctx.clearable,
    onChange: _ctx.change,
    onClear: _ctx.clear
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item[_ctx.remoteProp.codeKey],
          value: item[_ctx.remoteProp[_ctx.useNameKey ? 'nameKey' : 'codeKey']],
          label: _ctx.useLabel ? item[_ctx.remoteProp.nameKey] : ''
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item[_ctx.remoteProp.nameKey]), 1)
          ]),
          _: 2
        }, 1032, ["value", "label"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["placeholder", "remote-method", "loading", "clearable", "onChange", "onClear"]))
}