/*
 * @Description:
 * @Date: 2023/4/28 上午11:25:21
 */
import http from '@/utils/http/axios';

import { QuestionInfo } from './types';
import {
  CONFIGURABLE_TIME_TYPES,
  CONFIGURABLE_TYPES
} from '@/common/constants/configurable';

enum Api {
  getQuestion = '/project/getQuestion' // 获取项目题信息（调研和拜访）
}

// 获取项目题信息（调研和拜访）
export const getQuestion = async (params: { projectId: number }) => {
  const { data: res } = await http.request<Result<QuestionInfo>>({
    url: Api.getQuestion,
    method: 'POST',
    params
  });

  if (res.isSetLocation) {
    res.topics.unshift({
      type: -3,
      title: res.locationTitle,
      remark: res.locationRemark,
      options: [],
      rule: { isMustFill: 1, imageLeastNumber: 0, imageMostNumber: 0 },
      _rootType: -3,
      _rootTypeName: '定位题'
    });
  }
  if (res.isSetTime) {
    res.topics.unshift({
      type: res.timeType,
      title: res.timeTitle,
      remark: res.timeRemark,
      options: [],
      rule: { isMustFill: 1, imageLeastNumber: 0, imageMostNumber: 0 },
      _rootType: -2,
      _rootTypeName: CONFIGURABLE_TIME_TYPES[res.timeType]['label']
    });
  }
  if (res.isSetTarget) {
    res.topics.unshift({
      type: res.targetType,
      title: res.targetTitle,
      remark: res.targetRemark,
      options: [],
      rule: { isMustFill: 1, imageLeastNumber: 0, imageMostNumber: 0 },
      _rootType: -1,
      _rootTypeName: '目标题'
    });
  }

  res?.topics?.forEach((item, index) => {
    item['_rootTypeName'] = CONFIGURABLE_TYPES[item.type]?.label;
    item['_rootType'] = item._rootType || item.type;
    item['_index'] = index + 1;
    if (item['rule'] && typeof item['rule'] === 'object') {
      item['rule']['isMustFill'] = Number(item?.rule?.isMustFill) ? 1 : 0;
    } else {
      Reflect.set(item, 'rule', {});
    }
  });

  return res;
};
