/*
 * @Description: 登录
 * @Date: 2021-11-11 15:28:11
 */
import { validator } from '@helper/index';
import { login, logout, loginPhone } from '@data-source/user/apis';
import { LoginParams, LoginResult } from '@data-source/user/types';
interface UserEntity {
  ruleForm: LoginParams;
  syncData(params: LoginParams): Promise<LoginResult>;
}
class UserEntity {
  constructor() {
    this.ruleForm = {
      username: '',
      password: '',
      imgVerifyCode: '',
      verifyCode: '',
      phone: ''
    };
  }
  @validator()
  async passwordLogin() {
    const { data } = await login(this.ruleForm);
    return Promise.resolve(data);
  }
  @validator()
  async phoneLogin() {
    const { data } = await loginPhone(this.ruleForm);
    return Promise.resolve(data);
  }
  async logout() {
    await logout();
    return Promise.resolve();
  }
}

export default UserEntity;
