/*
 * @Description:
 * @Date: 2023/4/27 下午6:50:45
 */

import submitTask from '@V/submit-task/src/views/submit-task.vue';

export const route = [
  {
    name: 'submit-task',
    component: submitTask,
    meta: {
      title: 'submit-task',
      name: 'submit-task'
    }
  }
];
