/*
 * @Description:
 * @Date: 2023/5/16 下午15:57:21
 */

import { date } from 'xrk-tools';
import { signContractResult } from './types';
export const resultTranslatorsSignContract = (
  result: Result<signContractResult>
): Promise<Result<signContractResult>> => {
  return new Promise(resolve => {
    result.data.list.forEach(item => {
      item['_signingFinishDt'] = item.signingFinishDt
        ? date.dateFormat(item.signingFinishDt)
        : '-';
      // 0、1未签约
      // 2、 签约成功
      // 3、签约失败
      // 4、拒签
      item['_firstPartySignStatus'] = [
        '未签约',
        '未签约',
        '签约成功',
        '签约失败',
        '拒绝签约'
      ][item.firstPartySignStatus];
      item['_secondPartySignStatus'] = [
        '未签约',
        '未签约',
        '签约成功',
        '签约失败'
      ][item.secondPartySignStatus];
      // 2已完成和未完成
      item['_signStatus'] = ['未完成', '未完成', '已完成', '未完成'][
        item.signStatus
      ];
    });
    resolve(result);
  });
};
