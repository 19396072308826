/*
 * @Description: 项目
 * @Date: 2022-03-14 16:47:40
 */

// 背景
export const backgroundMapping = {
  1: { label: '医生', value: 1 },
  2: { label: '护士', value: 2 },
  3: { label: '健康管理师', value: 3 },
  4: { label: '市场人员', value: 4 },
  5: { label: '临床协调员', value: 5 },
  6: { label: '临床监察员', value: 6 },
  7: { label: '药店店员', value: 7 },
  8: { label: '药师', value: 8 },
  10: { label: '其他', value: 10 }
};
// 项目类型
export const projectTypeMapping = {
  1: { label: '调研服务', value: 1 },
  2: { label: '拜访服务', value: 2 }
};
// 项目审核状态
export const auditStatusMapping = {
  0: { label: '未提交', value: 0 },
  1: { label: '审核中', value: 1 },
  2: { label: '审核未通过', value: 2 },
  3: { label: '审核通过', value: 3 }
};
// 参与方
export const patternTypeMapping = {
  1: { label: '工业-ASP-P-C', value: 1 },
  2: {
    label: '工业-P-C',
    value: 2,
    onlyGongYe: true // 仅有工业
  },
  3: {
    label: 'ASP-P-C',
    value: 3,
    onlyASP: true, // 仅有ASP
    hideInAddProject: true // 发起项目中不展示
  },
  4: { label: '工业-ASP-C', value: 4 },
  5: { label: '工业-C', value: 5, onlyGongYe: true },
  6: { label: 'ASP-C', value: 6, onlyASP: true, hideInAddProject: true },
  7: { label: '工业-运营基地-C', value: 7 },
  8: { label: 'ASP-运营基地-C ', value: 8 },
  9: {
    label: '运营基地-C',
    value: 9,
    onlyGongYe: true,
    hideInAddProject: true
  },
  10: { label: '工业-ASP-个体', value: 10 },
  11: { label: '工业-个体', value: 11, onlyASP: true },
  12: { label: 'ASP-个体', value: 12, onlyASP: true, hideInAddProject: true },
  13: { label: '工业-ASP（线下支付）-P-C', value: 13 },
  14: { label: '工业（线下支付）-P-C', value: 14, onlyGongYe: true }
};
export const collectMoneyTypeMapping = {
  1: { label: '银行卡', value: 1 },
  2: { label: '钱包', value: 2 }
};
// 交易结构（税筹结构改为交易结构）
export const taxCollectionPatternMapping = {
  1: {
    label: '汇总申报',
    value: 1,
    // 该结构下的参与方
    patterns: [
      patternTypeMapping[1],
      patternTypeMapping[2],
      patternTypeMapping[3],
      patternTypeMapping[13],
      patternTypeMapping[14]
    ]
  },
  2: {
    label: '委托代开(线下)',
    value: 2,
    patterns: [
      patternTypeMapping[4],
      patternTypeMapping[5],
      patternTypeMapping[6]
    ]
  },
  3: {
    label: '委托代发',
    value: 3,
    patterns: [
      patternTypeMapping[1],
      patternTypeMapping[2],
      patternTypeMapping[3],
      patternTypeMapping[13],
      patternTypeMapping[14]
    ]
  },
  4: {
    label: '运营基地',
    value: 4,
    patterns: [patternTypeMapping[7], patternTypeMapping[9]]
  },
  5: {
    label: '个体',
    value: 5,
    patterns: [
      patternTypeMapping[10],
      patternTypeMapping[11],
      patternTypeMapping[12]
    ]
  },
  6: {
    label: '委托代开(线上)',
    value: 6,
    patterns: [
      patternTypeMapping[4],
      patternTypeMapping[5],
      patternTypeMapping[6]
    ]
  },
  9: {
    label: '工具',
    value: 9,
    patterns: [
      patternTypeMapping[4],
      patternTypeMapping[5],
      patternTypeMapping[6],
      patternTypeMapping[10],
      patternTypeMapping[11],
      patternTypeMapping[12]
    ]
  }
};
