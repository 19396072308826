/*
 * @Description:
 * @Date: 2021-09-26 15:02:05
 */
import http from '@/utils/http/axios';
import { Login, LoginParams, UpdateTransactorInfo } from './types';
enum Api {
  login = '/user/auth/passwordLogin', //  用户登录
  loginPhone = '/user/auth/login', //  手机验证码登录
  logout = '/user/auth/logout', // 退出
  getESignAuthUrl = '/user/auth/getESignAuthUrl', //获取实名认证连接
  getUserInfo = '/user/auth/getUserInfo', //获取当前登录用户信息
  updateTransactorInfo = '/user/manage/updateTransactorInfo' // 重新认证
}
export const updateTransactorInfo: UpdateTransactorInfo = async params =>
  http.request({
    url: Api.updateTransactorInfo,
    method: 'POST',
    params
  });
export const login: Login = async params =>
  http.request({
    url: Api.login,
    method: 'POST',
    params
  });
export const loginPhone: Login = async params =>
  http.request({
    url: Api.loginPhone,
    method: 'POST',
    params
  });
export const logout = async () =>
  http.request({
    url: Api.logout,
    method: 'POST'
  });
export const getESignAuthUrl: () => GApiResult<{ url: string }> = async () =>
  http.request({
    url: Api.getESignAuthUrl,
    method: 'POST'
  });

export const getUserInfo: () => GApiResult<LoginParams> = async () =>
  http.request({
    url: Api.getUserInfo,
    method: 'POST'
  });
