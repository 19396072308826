/*
 * @Description:
 * @Date: 2023/4/27 下午5:46:21
 */
import { ListSubmitTaskRecordData, ListUnSubmitTaskData } from './types';
import { SIGN_STATUS } from '../constants';
import { money, date } from 'xrk-tools';
export const listSubmitTaskRecordTransLate = (
  resp: Result<ListSubmitTaskRecordData>
) => {
  const {
    data: { list }
  } = resp;
  list.forEach(item => {
    item._signStatus = SIGN_STATUS[item.signStatus].label;
    item._projectUserAmount = money.moneyFixed(item.projectUserAmount);
    item._projectUserPrice = money.moneyFixed(item.projectUserPrice);
    item._submitDt = date.dateFormat(item.submitDt);
  });
  return resp;
};
export const listUnSubmitTaskTransLate = (
  resp: Result<ListUnSubmitTaskData>
) => {
  const {
    data: { list }
  } = resp;
  list.forEach(item => {
    item._terminalPrice = money.moneyFixed(+item.terminalPrice);
  });
  return resp;
};
