export const exportSearchList = '/rest/dlfb/asp/user/manage/download';
import http from '@/utils/http/axios';
import {
  personInformationParams,
  personInformationListData
} from '../data-source/types';
import { resultTranslatorsPersonInformation } from '../data-source/translators';

enum Api {
  userManageList = 'user/manage/list' //下载中心的列表
}
export const userManageListApi: (
  params: personInformationParams
) => Promise<Result<personInformationListData>> = async params => {
  const respond = await http.request<Result<personInformationListData>>({
    url: Api.userManageList,
    method: 'GET',
    params
  });
  return resultTranslatorsPersonInformation(respond);
};
