/*
 * @Description: 任务
 * @Date: 2021-11-17 19:51:20
 */
// 甲方、乙方、平台审核状态
// enum AuditCommonStatusEnum {
//   '未提交审核' = 0,
//   '审核中' = 1,
//   '审核通过' = 2,
//   '审核未通过' = 3
// }
// 结算状态
enum DataStateEnum {
  '未结算' = 0,
  '结算中' = 1,
  '已结算' = 2
}

const dataStateMapping = [
  { label: DataStateEnum[0], value: DataStateEnum.未结算 },
  { label: DataStateEnum[1], value: DataStateEnum.结算中 },
  { label: DataStateEnum[2], value: DataStateEnum.已结算 }
];
// 项目审核状态
const auditStatusMapping = {
  0: { label: '未提交', value: 0 },
  1: { label: '审核中', value: 1 },
  2: { label: '审核通过', value: 2 },
  3: { label: '审核未通过', value: 3 },
  5: { label: '审核中', value: 5 } // （重新提交）
};
// 是否过期
const isOverdueMapping = {
  0: { label: '未过期', value: 0 },
  1: { label: '已过期', value: 1 }
};
const qustionMapping = {
  '-3': {
    label: '定位题',
    value: -3
  },
  '-2': {
    label: '时间题',
    value: -2,
    children: {
      1: {
        label: '时间段题',
        value: -2,
        secondType: 1
      },
      2: {
        label: '时刻题',
        value: -2,
        secondType: 2
      }
    }
  },
  '-1': {
    label: '目标题',
    value: -1
  },
  1: {
    label: '单选题',
    value: 1
  },
  2: {
    label: '多选题',
    value: 2
  },
  3: {
    label: '单选投票题',
    value: 3
  },
  4: {
    label: '单项填空题',
    value: 4
  },
  5: {
    label: '多项填空题',
    value: 5
  },
  6: {
    label: '打分题',
    value: 6
  },
  7: {
    label: '地址',
    value: 7
  },
  8: {
    label: '上传附件',
    value: 8
  },
  9: {
    label: '上传pdf',
    value: 9
  },
  10: {
    label: '上传word',
    value: 10
  },
  11: {
    label: '上传excel',
    value: 11
  },
  12: {
    label: '上传ppt',
    value: 12
  },
  13: {
    label: '上传图片',
    value: 13
  },
  14: {
    label: '时间题',
    value: 14
  },
  15: {
    label: '月度题',
    value: 15
  },
  16: {
    label: '定位题',
    value: 16
  },
  17: {
    label: '描述题',
    value: 17
  },
  18: {
    label: '描述题',
    value: 18
  },
  19: {
    label: '数字填空题',
    value: 19
  },
  20: {
    label: '签名题',
    value: 20
  },
  21: {
    label: '签到题', // 未使用
    value: 21
  },
  22: {
    label: '姓名填空题',
    value: 22
  }
};
export {
  auditStatusMapping,
  dataStateMapping,
  isOverdueMapping,
  qustionMapping
};
