/*
 * @Description:
 * @Date: 2023/4/19 上午11:43:11
 */
import { markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import projectMgmtList from '../views/project-mgmt-list.vue';
import ProjectMgmtEdit from '../views/project-mgmt-edit.vue';

export const route = [
  {
    name: 'project-mgmt',
    path: '/project-mgmt',
    redirect: '/project-mgmt/project-mgmt-list',
    component: markRaw(RouterTransition),
    meta: {
      title: '项目管理',
      isRelation: true
    },
    children: [
      {
        name: 'project-mgmt-list',
        meta: {
          title: '项目列表'
        },
        component: projectMgmtList
      },
      {
        name: 'project-mgmt-edit',
        meta: {
          title: '编辑项目'
        },
        component: ProjectMgmtEdit
      }
    ]
  }
];
