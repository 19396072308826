/*
 * @Description:
 * @Date: 2023-02-13 09:50:01
 */
import http from '@/utils/http/axios';
import {
  OrderList,
  AmountDetailList,
  ModifyListData,
  ModifyListDetailData
} from './types';
import {
  orderListTranslator,
  amountDetailListTranslator,
  modifyListTransLator,
  modifyListDetailTransLator
} from './translators';
import { REQUEST_PATH } from '@/config';

enum Api {
  orderList = '/order/list', // 我的订单列表
  amountDetailList = '/order/amount/detail', // 我的订单列表-订单金额明细
  projectDetailList = '/order/project/detail', // 我的订单列表-项目明细
  modifyList = '/order/modify/list', // 操作修改-列表
  modifyListRemove = '/order/modify/list/remove', // 操作修改-列表
  modifyListDetail = '/order/modify/list/detail', // 操作修改-列表-详情
  downLoadWarn = '/order/downLoadWarn', // 我的订单列表-限额提示
  downLoadOrderExcel = '/order/downLoadOrderExcel', //下载支付记录&&下载支付详情
  viewFile = '/order/viewFile', //我的订单列表-查看凭证
  payPaying = '/pay/paying', //点击线下支付此时列表支付状态改为支付中
  deactivePay = '/pay/deactivePay', //取消支付（将支付中的订单取消）
  payFinish = '/pay/payFinish', //完成支付
  commonUpload = '/common/upload' //文件上传
}
export const orderList: OrderList = async params => {
  const resp = await http.request({
    url: Api.orderList,
    method: 'POST',
    params
  });
  return orderListTranslator(resp);
};
export const amountDetailList: AmountDetailList = async params => {
  const resp = await http.request({
    url: Api.amountDetailList,
    method: 'POST',
    params
  });
  return amountDetailListTranslator(resp);
};

export const projectDetailList: (params: { orderId: string }) => GApiResult<{
  list: {
    projectId: string;
    projectName: string;
    userSum: string; //项目人数
  }[];
}> = async params => {
  const resp = await http.request({
    url: Api.projectDetailList,
    method: 'POST',
    params
  });
  return resp;
};

export const modifyList: (params: {
  orderId: string;
}) => GApiResult<ModifyListData> = async params => {
  const resp = await http.request({
    url: Api.modifyList,
    method: 'POST',
    params
  });
  return modifyListTransLator(resp);
};

export const modifyListRemove: (params: {
  orderId: string;
  settlementIds: string;
}) => GApiResult<Result> = async params => {
  const resp = await http.request({
    url: Api.modifyListRemove,
    method: 'POST',
    params
  });
  return resp;
};

export const modifyListDetail: (params: {
  settlementId: string;
}) => GApiResult<ModifyListDetailData> = async params => {
  const resp = await http.request({
    url: Api.modifyListDetail,
    method: 'POST',
    params
  });
  return modifyListDetailTransLator(resp);
};

export const downLoadWarn: (params: { orderId: string }) => GApiResult<{
  isWarn: string | number; //1-弹info内容 0-不弹
  warnText: string; //提醒文案内容
}> = async params => {
  const resp = await http.request({
    url: Api.downLoadWarn,
    method: 'POST',
    params
  });
  return resp;
};

export const downLoadOrderExcel: (params: {
  orderId: string | number;
}) => string = params => {
  return `${REQUEST_PATH}${Api.downLoadOrderExcel}?orderId=${params.orderId}`;
};

export const viewFile: (params: { orderId: string }) => GApiResult<
  {
    createDt: number;
    filePath: string;
    fileType: string;
    id: number;
    orderId: number;
  }[]
> = async params => {
  const resp = await http.request({
    url: Api.viewFile,
    method: 'POST',
    params
  });
  return resp;
};

export const payPaying: (params: {
  orderId: string;
}) => GApiResult<Result> = async params => {
  const resp = await http.request({
    url: Api.payPaying,
    method: 'POST',
    params
  });
  return resp;
};

export const deactivePay: (params: {
  orderId: string;
}) => GApiResult<Result> = async params => {
  const resp = await http.request({
    url: Api.deactivePay,
    method: 'POST',
    params
  });
  return resp;
};

export const payFinish: (params: {
  orderId: string;
  files: string;
}) => GApiResult<Result> = async params => {
  const resp = await http.request({
    url: Api.payFinish,
    method: 'POST',
    params
  });
  return resp;
};

export const commonUpload: (params) => Promise<Result> = async params => {
  const respond = await http.uploadFile(
    {
      url: Api.commonUpload,
      method: 'POST'
    },
    params
  );
  return respond as unknown as Result;
};
