/*
 * @Description:下载中心的table字段
 * @Date: 2023-04-21 14:04:38
 */
import { useTableColumn } from 'xrk-components';
export const index = useTableColumn({
  type: 'index',
  label: '序号',
  align: 'center'
});
export const downloadName = useTableColumn({
  label: '文件名称',
  prop: 'downloadName',
  render({ row }) {
    const { downloadName } = row;
    return (
      <div
        style={{
          whiteSpace: 'initial'
        }}
      >
        {downloadName}
      </div>
    );
  }
});
export const fileSize = useTableColumn({
  label: '文件大小',
  prop: '_fileSize'
});
export const status = useTableColumn({
  label: '文件生成及进度',
  prop: '_status'
});
export const downloadDt = useTableColumn({
  label: '下载完成时间',
  prop: '_downloadDt'
});
