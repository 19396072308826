/*
 * @Description:
 * @Date: 2023-03-23 13:52:04
 */
import {
  OrderListData,
  AmountDetailListData,
  ModifyListData,
  ModifyListDetailData
} from './types';
import { PAY_STATUS } from '../constants';
import { money, date } from 'xrk-tools';
export const orderListTranslator = (resp: Result<OrderListData>) => {
  const {
    data: { list }
  } = resp;
  list.forEach(item => {
    item._createDt = date.dateFormat(item.createDt);
    item._amount = money.moneyFixed(item.amount);
    item._payStatus = PAY_STATUS[item.payStatus].label;
  });

  return resp;
};

export const amountDetailListTranslator = (
  resp: Result<AmountDetailListData>
) => {
  const {
    data: { list }
  } = resp;
  list.forEach(item => {
    item._projectUserAmount = money.moneyFixed(item.projectUserAmount);
    item._projectUserPrice = money.moneyFixed(item.projectUserPrice);
  });

  return resp;
};

export const modifyListTransLator = (resp: Result<ModifyListData>) => {
  const {
    data: { list }
  } = resp;
  list.forEach(item => {
    item._createDt = date.dateFormat(item.createDt);
    item._projectUserAmount = money.moneyFixed(item.projectUserAmount);
    item._payStatus = PAY_STATUS[item.payStatus].label;
  });

  return resp;
};

export const modifyListDetailTransLator = (
  resp: Result<ModifyListDetailData>
) => {
  const {
    data: { list }
  } = resp;
  list.forEach(item => {
    item._projectUserAmount = money.moneyFixed(item.projectUserAmount);
    item._projectUserPrice = money.moneyFixed(item.projectUserPrice);
  });

  return resp;
};
