/*
 * @Description:
 * @Date: 2023/4/27 下午5:46:21
 */

import taskRecord from '@V/task-record/src/views/task-record/task-record.vue';

export const route = [
  {
    name: 'task-record',
    component: taskRecord,
    meta: {
      title: 'task-record',
      name: 'task-record'
    }
  }
];
