import { useTableColumn } from 'xrk-components';
export const title = useTableColumn({
  prop: 'title',
  label: '轮播图名称'
});
export const status = useTableColumn({
  prop: '_status',
  label: '启用状态'
});
export const createDt = useTableColumn({
  prop: '_createDt',
  label: '启用时间'
});
