/*
 * @Description:下载中心的搜索字段
 * @Date: 2023-04-21 13:51:38
 *
 */
import { DATEPICKER_DEFAULT_VALUE } from '../constants/index';

import { useInputColumn, useDatePickerColumn } from 'xrk-components';
export const downloadNameSearch = useInputColumn({
  prop: 'downloadName',
  label: '文件名称：',
  type: 'input',
  config: {
    placeholder: '请输入文件名称',
    clearable: true,
    maxlength: 30
  }
});
export const dateValueSearch = useDatePickerColumn({
  defaultValue: DATEPICKER_DEFAULT_VALUE,
  prop: 'dateValue',
  label: '下载时间：',
  config: {
    clearable: true,
    type: 'daterange',
    startPlaceholder: '下载时间起',
    endPlaceholder: '下载时间止'
  }
});
