/*
 * @Description:
 * @Date: 2022-03-14 19:18:12
 */

import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { Menus } from '@data-source/user/types';

const SYSTEM_MENUS: Array<Menus> = [
  {
    menu_code: 'project-mgmt-list',
    menu_name: '首页',
    menus: []
  },
  {
    menu_code: 'project-detail',
    menu_name: '项目详情',
    menus: [
      {
        menu_code: 'project-mgmt-edit',
        menu_name: '编辑项目',
        menus: [],
        meta: {
          icon: require('@/assets/img/menu-icon/项目详情1.svg'), // 默认
          activeIcon: require('@/assets/img/menu-icon/项目详情.svg') // 激活
        }
      },
      {
        menu_code: 'project-personnel',
        menu_name: '项目人员',
        menus: [],
        meta: {
          icon: require('@/assets/img/menu-icon/项目人员1.svg'),
          activeIcon: require('@/assets/img/menu-icon/项目人员.svg')
        }
      },
      {
        menu_code: 'task-record',
        menu_name: '任务记录',
        menus: [],
        meta: {
          icon: require('@/assets/img/menu-icon/任务记录1.svg'),
          activeIcon: require('@/assets/img/menu-icon/任务记录.svg')
        }
      },
      {
        menu_code: 'submit-task',
        menu_name: '提交任务',
        menus: [],
        meta: {
          icon: require('@/assets/img/menu-icon/确认任务1.svg'),
          activeIcon: require('@/assets/img/menu-icon/确认任务.svg')
        }
      }
    ]
  },
  {
    menu_code: 'download-center-list',
    menu_name: '下载中心',
    menus: []
  },
  {
    menu_code: 'settled',
    menu_name: '结算中心',
    menus: [
      {
        menu_code: 'project-settled-list',
        menu_name: '项目结算管理',
        menus: [],
        meta: {
          icon: require('@/assets/img/menu-icon/项目结算管理1.png'), // 默认
          activeIcon: require('@/assets/img/menu-icon/项目结算管理.png') // 激活
        }
      },
      {
        menu_code: 'order-mgmt-list',
        menu_name: '我的订单',
        menus: [],
        meta: {
          icon: require('@/assets/img/menu-icon/我的订单1.png'), // 默认
          activeIcon: require('@/assets/img/menu-icon/我的订单.png') // 激活
        }
      }
    ]
  },
  {
    menu_code: 'person-information-list',
    menu_name: '自然人信息管理',
    menus: []
  },
  {
    menu_code: 'sign-contract-mgmt',
    menu_name: '签约及合同管理',
    menus: [
      {
        menu_code: 'sign-contract-list',
        menu_name: '自然人合同管理',
        menus: [],
        meta: {
          icon: require('@/assets/img/menu-icon/自然人合同1.png'), // 默认
          activeIcon: require('@/assets/img/menu-icon/自然人合同.png') // 激活
        }
      }
    ]
  },
  {
    menu_code: 'other',
    menu_name: '其他功能',
    menus: [
      {
        menu_code: 'app-banner-list',
        menu_name: '小程序轮播图管理',
        menus: [],
        meta: {
          icon: require('@/assets/img/menu-icon/轮播图管理1.png'), // 默认
          activeIcon: require('@/assets/img/menu-icon/轮播图管理.png') // 激活
        }
      }
    ]
  }
];
const HIDEN_HORIZONTAL_MENUS = ['project-detail'];

const name = 'useMenuStoreSetup';
const useSetup = defineStore(
  name,
  () => {
    const menus = ref<Array<Menus>>([]);
    // 横向菜单暂不需要动态设置
    const horizontal = computed(() => {
      return SYSTEM_MENUS.filter(
        item => !HIDEN_HORIZONTAL_MENUS.includes(item.menu_code)
      );
    });
    // 纵向菜单动态设置
    const vertical = ref([]);
    // 当前menu菜单
    const currentMenu = ref<Menus>(null);

    // 是否显示横向菜单
    const showHorizontal = computed(() => {
      if (
        currentMenu.value &&
        HIDEN_HORIZONTAL_MENUS.includes(currentMenu.value?.menu_code)
      ) {
        return false;
      }

      return true;
    });
    // 是否显示纵向菜单
    const showVertival = computed(() => {
      return !!vertical.value.length;
    });

    // 设置横纵菜单，目前只设置纵向
    const setHVMenus = name => {
      const verticalMenu = findTopMenu(SYSTEM_MENUS, name);
      currentMenu.value = verticalMenu;
      vertical.value = verticalMenu?.menus || [];
    };
    const findTopMenu = (meuns, routeName) => {
      for (const i in meuns) {
        const menuCodeI = meuns[i].menu_code;
        if (menuCodeI === routeName) {
          return meuns[i];
        }
        if (meuns[i].menus?.length) {
          if (findTopMenu(meuns[i].menus, routeName)) {
            return meuns[i];
          }
        }
      }
      return null;
    };
    function setMenus(menu) {
      menus.value = menu;
    }
    return {
      menus,
      setMenus,
      horizontal,
      vertical,
      findTopMenu,
      showHorizontal,
      showVertival,
      setHVMenus
    };
  },
  {
    persist: true
  }
);

export { useSetup, name };
