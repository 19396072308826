/*
 * @Description: 注册store。 遍历views中的app.ts
 * @Date: 2021-03-22 13:08:59
 */
import { App } from 'vue';
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';

const pinia = createPinia();
pinia.use(
  createPersistedState({
    storage: localStorage,
    serializer: {
      serialize: JSON.stringify,
      deserialize: JSON.parse
    }
  })
);

const appStore: any = {};

const registerStore = () => {
  const modules = require.context('./modules', true, /.js|.ts$/);
  modules.keys().forEach(path => {
    const curStore = modules(path);
    if (curStore && curStore.name && curStore.useSetup) {
      appStore[curStore.name] = curStore.useSetup();
    }
  });
};

const setupStore = (app: App) => {
  app.use(pinia);
  registerStore();
};
export { pinia, setupStore };
export default appStore;
