/*
 * @Description:
 * @Date: 2023-04-23 11:43:02
 */
import { markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import personInformationList from '../views/person-information-list.vue';

export const route = [
  {
    path: '/person-information',
    name: 'person-information',
    redirect: '/person-information/person-information-list',
    component: markRaw(RouterTransition),
    meta: {
      title: '自然人信息管理',
      isRelation: true
    },
    children: [
      {
        name: 'person-information-list',
        meta: {
          title: '自然人信息管理'
        },
        component: personInformationList
      }
    ]
  }
];
