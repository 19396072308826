/*
 * @Description:
 * @Date: 2021-10-20 11:43:02
 */
import { markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import OrderMgmtList from '../views/order-mgmt-list.vue';

export const route = [
  {
    path: '/order-mgmt',
    name: 'order-mgmt',
    redirect: '/order-mgmt/order-mgmt-list',
    component: markRaw(RouterTransition),
    meta: {
      title: '我的订单',
      isRelation: true
    },
    children: [
      {
        name: 'order-mgmt-list',
        meta: {
          title: '我的订单'
        },
        component: OrderMgmtList
      }
    ]
  }
];
