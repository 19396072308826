/*
 * @Description:
 * @Date: 2023/4/28 上午11:25:21
 */
import http from '@/utils/http/axios';
import { REQUEST_PATH } from '@/config';
enum Api {
  send = '/msg/send', //发送验证码
  getVerificationTextImg = '/get/verification/textImg', //获取图形验证码
  checkVerifyCodeForPassword = '/user/auth/checkVerifyCodeForPassword', //修改密码时校验短信验证码
  resetPwd = '/user/auth/resetPwd', //修改密码时校验短信验证码
  perfectPwd = '/user/auth/perfectPwd' //完善密码
}

export const send: (params: {
  phone: string | number;
  imgVerifyCode: string | number;
}) => GApiResult<Result> = async params => {
  return await http.request({
    url: Api.send,
    method: 'POST',
    params
  });
};

export const getVerificationTextImg = () => {
  return `${REQUEST_PATH}${Api.getVerificationTextImg}`;
};

export const checkVerifyCodeForPassword: (params: {
  phone: string | number;
  verifyCode: string | number;
}) => GApiResult<Result> = async params => {
  return await http.request({
    url: Api.checkVerifyCodeForPassword,
    method: 'POST',
    params
  });
};

export const resetPwd: (params: {
  phone: string | number;
  verifyCode: string | number;
  password: string;
  confirmPassword: string;
}) => GApiResult<{ username?: string }> = async params => {
  return await http.request({
    url: Api.resetPwd,
    method: 'POST',
    params
  });
};

export const perfectPwd: (params: {
  password: string;
  confirmPassword: string;
}) => GApiResult<{ username?: string }> = async params => {
  return await http.request({
    url: Api.perfectPwd,
    method: 'POST',
    params
  });
};
