
import { defineComponent, watch } from 'vue';
import LayoutToolbar from './components/toolbar/index.vue';
import LayoutMenuHorizontal from './components/menu/layout-menu-horizontal.vue';
import LayoutMenuVertical from './components/menu/layout-menu-vertical.vue';
import LayoutBreadcrumb from './components/breadcrumb/index.vue';
import { RouterTransition } from '@/components/transition/index';
import appStore from '@/store';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'layout-index',
  components: {
    LayoutToolbar,
    LayoutMenuHorizontal,
    LayoutMenuVertical,
    LayoutBreadcrumb,
    RouterTransition
  },
  props: {
    toolbar: {
      type: Boolean,
      default: true
    },
    menu: {
      type: Boolean,
      default: true
    },
    breadcrumb: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const route = useRoute();
    const { setHVMenus } = appStore.useMenuStoreSetup;
    const { showHorizontal, showVertival } = storeToRefs(
      appStore.useMenuStoreSetup
    );

    watch(
      () => route.name,
      v => {
        setHVMenus(v);
      },
      {
        immediate: true
      }
    );
    return {
      showHorizontal,
      showVertival
    };
  }
});
