/*
 * @Description:
 * @Date: 2023/4/19 上午11:43:11
 */
import { markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import QuestionnaireMgmtEdit from '../views/questionnaire-mgmt-edit.vue';

export const TopicsEditPageName = 'questionnaire-mgmt-edit';

export const route = [
  {
    name: 'questionnaire-mgmt',
    path: '/questionnaire-mgmt',
    redirect: '/questionnaire-mgmt/questionnaire-mgmt-edit',
    component: markRaw(RouterTransition),
    meta: {
      title: '问卷管理',
      isRelation: true
    },
    children: [
      {
        name: TopicsEditPageName,
        meta: {
          title: '编辑问卷'
        },
        component: QuestionnaireMgmtEdit
      }
    ]
  }
];
