
import { defineComponent, ref, onBeforeMount } from 'vue';
import { useRouter, RouteLocation, useRoute } from 'vue-router';
import { ArrowRight } from '@element-plus/icons-vue';
import { projectGet } from '@/views/project-mgmt/src/data-source/apis';
export default defineComponent({
  name: 'layout-breadcrumb',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const routeQuery: { projectId: number } = {
      projectId: +route.query.projectId
    };
    const projectName = ref(null);
    const routes = ref<Array<RouteLocation>>([]);
    const setBreadcrumbRoutes = route => {
      const { matched, name, query } = route;
      routes.value = matched.filter((item, index) => {
        // 为当前route设置进入时参数。未支持params
        if (item.name === name) {
          item.query = query;
        }
        if (item.meta.isRelation) {
          // index [0]layout [1]容器 [2]当前组件
          return item.redirect !== matched[index + 1].path; // redirect === path是容器redirect的页面，这种不需要展示层级
        }
        return !/noLayout|isIndependent|isDynamicContainer|isLayou|isRelation/.test(
          Object.keys(item.meta) + ''
        );
      });
    };
    const onHome = () => {
      router.push({
        name: 'project-mgmt-list',
        query: {
          t: Date.now()
        }
      });
    };
    router.beforeEach(to => {
      setBreadcrumbRoutes(to);
    });

    onBeforeMount(() => {
      setBreadcrumbRoutes(router.currentRoute.value);

      if (routeQuery.projectId) {
        setTimeout(() => {
          projectGet({ id: routeQuery.projectId, t: +new Date() }).then(res => {
            projectName.value = res.data.projectName;
          });
        }, 1);
      }
    });

    return {
      routes,
      ArrowRight,
      projectName,
      onHome
    };
  }
});
