/*
 * @Description:
 * @Date: 2023/4/19 上午11:43:11
 */
import { ProjectListData } from './types';
import { projectTypeMapping } from '@constants/project';

export const listTranslator = (result: Result<ProjectListData>) => {
  const {
    data: { list }
  } = result;
  list.forEach(item => {
    item._projectTypeStr = `${projectTypeMapping[item.projectType].label}${
      item.visitTargetType ? `/${item.visitTargetType}` : ''
    }`;
    item._projectPeopleSum = item.projectPeopleSum || '0';
    item._balanceTaskSum = item.balanceTaskSum || '0';
    item._passingTaskSum = item.passingTaskSum || '0';
    item._passTaskSum = item.passTaskSum || '0';
  });

  return result;
};
