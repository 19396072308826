/*
 * @Description:
 * @Date: 2023/4/27 下午5:46:21
 */
import http from '@/utils/http/axios';
import {
  taskRecordListTranslator,
  summaryListTranslator,
  projectDetailTranslator
} from './translators';
import { REQUEST_PATH } from '@/config';

import {
  AuditParams,
  TaskRecordListParams,
  TaskRecordListData,
  TaskRecordListExcelParams,
  DetailParams,
  DetailAuditParams,
  DetailAuditResult,
  SummaryListParams,
  SummaryListData
} from './types';
import QueryString from 'qs';
enum Api {
  audit = '/task/audit', // 任务记录审核
  taskRecordList = '/task/list', // 列表
  listExcel = '/task/listExcel', // 列表下载导出
  detail = '/task/detail', // 详情
  detailAudit = '/task/detailAudit', // 详情里审核
  summaryList = '/summary/list', //任务汇总列表
  summaryListExcel = '/summary/listExcel' //导出任务汇总列表
}
// 任务记录审核
export const audit: (
  params: AuditParams
) => GApiResult<DetailAuditResult> = async params => {
  const respond = await http.request({
    url: Api.audit,
    method: 'POST',
    params
  });
  return respond;
};
// 列表
export const taskRecordList: (
  params: TaskRecordListParams
) => GApiResult<TaskRecordListData> = async params => {
  const respond = await http.request({
    url: Api.taskRecordList,
    method: 'POST',
    params
  });
  return taskRecordListTranslator(respond);
};

// 任务记录列表下载导出
export const taskRecordListExcel = (params: TaskRecordListExcelParams) => {
  return `${REQUEST_PATH}${Api.listExcel}?${QueryString.stringify(params)}`;
};
// 详情
export const detail: (
  params: DetailParams
) => GApiResult<Result> = async params => {
  const respond = await http.request({
    url: Api.detail,
    method: 'POST',
    params
  });
  return projectDetailTranslator(respond);
};
// 详情里审核
export const detailAudit: (
  params: DetailAuditParams
) => GApiResult<DetailAuditResult> = async params => {
  const respond = await http.request({
    url: Api.detailAudit,
    method: 'POST',
    params
  });
  return respond;
};

// 列表
export const summaryList: (
  params: SummaryListParams
) => GApiResult<SummaryListData> = async params => {
  const respond = await http.request({
    url: Api.summaryList,
    method: 'POST',
    params
  });
  return summaryListTranslator(respond);
};

//任务汇总导出列表
export const summaryListExcel = (params: SummaryListParams) => {
  return `${REQUEST_PATH}${Api.summaryListExcel}?${QueryString.stringify(
    params
  )}`;
};
