/*
 * @Description:
 * @Date: 2023-03-22 15:34:38
 */
import { useInputColumn } from 'xrk-components';
export const projectNameSearch = useInputColumn({
  prop: 'projectName',
  type: 'input',
  style: {
    width: '230px'
  },
  config: {
    placeholder: '项目名称',
    clearable: true,
    maxlength: 20
  }
});
export const projectNoSearch = useInputColumn({
  prop: 'projectNo',
  type: 'input',
  style: {
    width: '230px'
  },
  config: {
    placeholder: '项目编码',
    clearable: true,
    maxlength: 20
  }
});
