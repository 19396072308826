/*
 * @Description:
 * @Date: 2023-03-23 13:52:04
 */
import { SettlementListData, WaitPayDetailData } from './types';
import { PAY_STATUS } from '../constants';
import { money, date } from 'xrk-tools';

export const settlementListTranslator = (resp: Result<SettlementListData>) => {
  const {
    data: { list }
  } = resp;
  list.forEach(item => {
    item._createDt = item.createDt ? date.dateFormat(item.createDt) : '-';
    item._orderCreateDt = item.orderCreateDt
      ? date.dateFormat(item.orderCreateDt)
      : '-';
    item._orderPayDt = item.orderPayDt ? date.dateFormat(item.orderPayDt) : '-';
    item._projectUserAmount = money.moneyFixed(item.projectUserAmount);
    item._payStatus = (PAY_STATUS[item.payStatus] || { label: '-' }).label;
    item._orderNo = item.orderNo || '-';
  });
  return resp;
};

export const waitPayDetailTranslator = (resp: Result<WaitPayDetailData>) => {
  const {
    data: { list }
  } = resp;
  list.forEach(item => {
    item._projectUserPrice = money.moneyFixed(item.projectUserPrice);
    item._projectUserAmount = money.moneyFixed(item.projectUserAmount);
  });
  return resp;
};
