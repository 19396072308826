/*
 * @Description: 用户系统 - 之前的结构，不要参考。会重构，暂时功能使用
 * @Date: 2021-09-22 12:13:07
 */

import { route } from './src/router';
import UserUpdatePassword from './src/views/user-update-password.vue';

const name = 'user';

export { name, route, UserUpdatePassword };
