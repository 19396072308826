// axios配置  可自行根据项目进行更改，只需更改该文件即可，其他文件可以不动

import { VAxios } from './axios';
import { AxiosTransform } from './axios-transform';
import axios from 'axios';
import qs from 'qs';
import { checkStatus } from './check-status';
import { ElMessage, ElMessageBox } from 'element-plus';
import { RequestEnum, ResultEnum, ContentTypeEnum } from './enums';
import { isString } from '@/utils/is/index';
import { setObjToUrlParams } from '@/utils/url';
import { RequestOptions, Result } from './types';
import router from '@/router';
import { TOKEN_KEY, REQUEST_PATH } from '@/config';
import appStore from '@/store';
import { storeToRefs } from 'pinia';
import { useIntEntStatus } from '@/common/hooks/user';

// import SetPassword from '@/views/login/components/set-password.vue';
const singletonLogout = (() => {
  let flag = false;
  return () => {
    if (!flag) {
      flag = true;
      // 到登录页
      ElMessageBox.confirm('登录身份已失效,请重新登录!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          appStore.useUserStoreSetup.clearUser({
            redirect: router.currentRoute.value.fullPath
          });
        })
        .finally(() => {
          flag = false;
        });
    }
  };
})();

// const singletonSetPassword = (() => {
//   let flag = false;
//   return () => {
//     if (!flag) {
//       flag = true;
//       // useCreateModal(SetPassword, {
//       //   onOk() {
//       //     window.location.reload();
//       //     flag = false;
//       //   }
//       // });
//     }
//   };
// })();
const isDev = process.env.NODE_ENV === 'development';

/**
 * @description: 数据处理，方便区分多种处理方式
 */
const transform: AxiosTransform = {
  /**
   * @description: 处理请求数据
   */
  transformRequestData: (
    res: Result, //AxiosResponse<Result>,
    options: RequestOptions
  ) => {
    const {
      isTransformRequestResult,
      isShowMessage = true,
      isShowErrorMessage,
      isShowSuccessMessage,
      successMessageText,
      errorMessageText
    } = options;

    const reject = msg => Promise.reject(msg);

    //  这里  code, data, message, status为 后台统一的字段，需要在 types.ts内修改为项目自己的接口返回格式
    const { code, message, status } = res;
    // 请求成功
    const isSuccess =
      res && Reflect.has(res, 'status') && +status === ResultEnum.SUCCESS;
    // 是否显示提示信息
    if (isShowMessage) {
      if (isSuccess && (successMessageText || isShowSuccessMessage)) {
        // 是否显示自定义信息提示
        ElMessage.success(successMessageText || message || '操作成功！');
      } else if (!isSuccess && (errorMessageText || isShowErrorMessage)) {
        // 是否显示自定义信息提示
        ElMessage.error(message || errorMessageText || '操作失败！');
      } else if (!isSuccess && options.errorMessageMode === 'modal') {
        // errorMessageMode=‘custom-modal’的时候会显示modal错误弹窗，而不是消息提示，用于一些比较重要的错误
        ElMessageBox.confirm(message, '错误提示');
      }
    }
    // 不进行任何处理，直接返回
    // 用于页面代码可能需要直接获取status,code，data，message这些信息时开启
    if (!isTransformRequestResult) {
      return res;
    }

    if (!res) {
      // return '[HTTP] Request has no return value';
      return reject(res);
    }
    // 接口请求成功，直接返回结果
    if (+status === ResultEnum.SUCCESS) {
      return res;
    }
    // 登录过期
    if (+code === ResultEnum.TIMEOUT) {
      // if (router.currentRoute.value.name == 'login')
      //   return reject(new Error('need login'));
      // singletonLogout();
      const timeoutMsg = '登录超时,请重新登录!';
      return reject(new Error(timeoutMsg));
    }
    // 强制设置密码
    if (+code === ResultEnum.SETPASSWORD) {
      // if (router.currentRoute.value.name == 'login')
      //   return reject(new Error('timeoutMsg'));
      // singletonSetPassword();
      const timeoutMsg = '请设置登录密码!';
      return reject(new Error(timeoutMsg));
    }
    //企业未认证
    if (+code === ResultEnum.ENTERPRICE_NOT_AUTH) {
      const msg = '在执行业务前，需要您完成企业实名认证';
      //打开企业实名认证
      useIntEntStatus(0, msg);
      // ElMessage.error(msg);
      return reject(new Error(msg));
    }
    //企业认证中
    if (+code === ResultEnum.ENTERPRICE_AUTHING) {
      const msg = '实名认证中，预计1-3个工作日内完成审核。';
      //打开企业实名认证
      useIntEntStatus(1, msg);
      // ElMessage.error(msg);
      return reject(new Error(msg));
    }

    // 接口请求错误，统一提示错误信息
    if (+code === ResultEnum.LOGIN) {
      const msg = message || '操作失败,系统异常!';

      ElMessage.error(msg);
      singletonLogout();

      return reject(new Error(msg));
    }

    // 接口请求错误，统一提示错误信息
    if (+status === ResultEnum.ERROR) {
      const msg = message || '操作失败,系统异常!';
      ElMessage.error(msg);
      return reject(new Error(msg));
    }

    if (!isSuccess) {
      return reject(new Error(message || '操作失败,系统异常!'));
    }
    return res;
  },

  // 请求之前处理config
  beforeRequestHook: (config, options) => {
    const {
      apiUrl,
      joinPrefix: _joinPrefix,
      joinParamsToUrl,
      formatDate: _formatDate,
      isParseToJson,
      yapi
    } = options;
    config.url = isDev ? `${config.url}` : `${apiUrl || ''}${config.url}`;
    // 使用yapi
    if (yapi && isDev) {
      config.baseURL = `http://yapi.jimijiayuan.cn/mock/${yapi}${REQUEST_PATH}`;
    }
    if (config.method === RequestEnum.GET) {
      const now = new Date().getTime();
      if (!isString(config.params)) {
        config.data = {
          // 给 get 请求加上时间戳参数，避免从缓存中拿数据。
          params: Object.assign(config.params || {}, {
            _t: now
          })
        };
      } else {
        // 兼容restful风格
        config.url = config.url + config.params + `?_t=${now}`;
        config.params = {};
      }
    } else {
      if (!isString(config.params)) {
        config.data = config.params;
        config.params = {};
        if (joinParamsToUrl) {
          config.url = setObjToUrlParams(config.url as string, config.data);
        }
      } else {
        // 兼容restful风格
        config.url = config.url + config.params;
        config.params = {};
      }
      // 'a[]=b&a[]=c'
      if (!isParseToJson) {
        config.params = qs.stringify(config.params, {
          arrayFormat: 'brackets'
        });
        config.data = qs.stringify(config.data, { arrayFormat: 'brackets' });
      }
    }
    return config;
  },

  /**
   * @description: 请求拦截器处理
   */
  requestInterceptors: config => {
    // 请求之前处理config
    const { info } = storeToRefs(appStore.useUserStoreSetup);
    const token = info.value.token;
    if (token) {
      // jwt token
      config.headers[TOKEN_KEY] = token;
    }
    return config;
  },
  /**
   * @description: 响应数据处理
   */
  responseInterceptors: (resp: any) => {
    const { data } = resp;
    const respData = data || resp;
    return respData;
  },
  /**
   * @description: 响应错误处理
   */
  responseInterceptorsCatch: (error: any) => {
    const { response, code, message } = error || {};
    const msg: string =
      response && response.data && response.data.error
        ? response.data.error.message
        : '';
    const err: string = error.toString();
    try {
      if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
        ElMessage.error('接口请求超时,请刷新页面重试!');
        return;
      }
      if (err && err.includes('Network Error')) {
        ElMessage.error('请检查您的网络连接是否正常!');
        return;
      }
    } catch (error) {
      throw new Error('error');
    }
    // 请求是否被取消
    const isCancel = axios.isCancel(error);
    if (!isCancel) {
      checkStatus(error.response && error.response.status, msg);
    } else {
      console.warn(error, '请求被取消！');
    }
    return error;
  }
};

const Axios = new VAxios({
  timeout: 1000 * 60 * 5,
  // 基础接口地址
  baseURL: REQUEST_PATH,
  // 接口可能会有通用的地址部分，可以统一抽取出来
  // prefixUrl: prefix,
  headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED },
  // 数据处理方式
  transform,
  // 配置项，下面的选项都可以在独立的接口请求中覆盖
  requestOptions: {
    // 默认将prefix 添加到url
    joinPrefix: true,
    // 需要对返回数据进行处理
    isTransformRequestResult: true,
    // post请求的时候添加参数到url
    joinParamsToUrl: false,
    // 格式化提交参数时间
    formatDate: true,
    // 消息提示类型
    errorMessageMode: 'none'
  },
  withCredentials: false
});

export default Axios;
