/*
 * @Description:下载中心的table字段
 * @Date: 2023-04-21 14:04:38
 */
import { useTableColumn } from 'xrk-components';
export const index = useTableColumn({
  type: 'index',
  label: '序号',
  align: 'center'
});
export const fullName = useTableColumn({
  label: '姓名',
  prop: 'fullName'
});
export const phone = useTableColumn({
  label: '手机号',
  prop: 'phone'
});
export const firstPartySignStatus = useTableColumn({
  label: '企业签约状态',
  prop: '_firstPartySignStatus'
});
export const secondPartySignStatus = useTableColumn({
  label: '自然人签约状态',
  prop: '_secondPartySignStatus'
});
export const signStatus = useTableColumn({
  label: '合同签约状态',
  prop: '_signStatus'
});
export const signingFinishDt = useTableColumn({
  label: '签约完成时间',
  prop: '_signingFinishDt'
});
