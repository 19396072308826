/*
 * @Description: 系统
 * @Date: 2022-09-23 14:32:56
 */

export const LOGIN_PAGE_NAME = 'login'; // 登录页面name
export const DEFAULT_PAGE_NAME = 'home'; // 没有默认页，以menus[0]作为默认页
export const WHITE_LIST: Array<string | symbol> = ['cash-out-audit'];
export const TOKEN_BLACK_LIST: Array<string | symbol> = [];
export const MENUS = [
  {
    menu_code: 'project-mgmt',
    menu_name: '项目管理',
    menus: [
      // {
      //   menu_code: 'project-mgmt-list',
      //   menu_name: '项目列表',
      //   menus: []
      // },
      // {
      //   menu_code: 'project-mgmt-edit',
      //   menu_name: '项目编辑',
      //   menus: []
      // }
    ]
  },
  {
    menu_code: 'project-personnel',
    menu_name: '项目人员',
    menus: []
  },
  {
    menu_code: 'task-record',
    menu_name: '任务记录',
    menus: []
  },
  {
    menu_code: 'submit-task',
    menu_name: '提交任务',
    menus: []
  },
  // {
  //   menu_code: 'cash-mgmt',
  //   menu_name: '提现管理',
  //   menus: [
  //     // {
  //     //   menu_code: 'cash-mgmt-list',
  //     //   menu_name: '供应商列表',
  //     //   menus: []
  //     // }
  //   ]
  // },
  // {
  //   menu_code: 'project-migration',
  //   menu_name: '项目迁移',
  //   menus: []
  // },
  {
    menu_code: 'project-settled',
    menu_name: '项目结算管理',
    menus: []
  },
  {
    menu_code: 'order-mgmt',
    menu_name: '订单管理',
    menus: []
  },
  {
    menu_code: 'download-center',
    menu_name: '下载中心',
    menus: []
  },
  {
    menu_code: 'person-information',
    menu_name: '自然人信息管理',
    menus: []
  },
  {
    menu_code: 'sign-contract',
    menu_name: '签约及合同管理',
    menus: []
  },
  {
    menu_code: 'app-banner',
    menu_name: '其他功能',
    menus: []
  },
  {
    menu_code: 'questionnaire-mgmt',
    menu_name: '问卷编辑',
    menus: []
  }
];
export const STATUS_MAPPING = {
  1: {
    label: '有效',
    value: 1
  },
  2: {
    label: '无效',
    value: 2
  }
};
export const PLATFORM_MAPPING = {
  1: {
    label: 'OSS',
    value: 1
  },
  2: {
    label: 'PAY_OSS',
    value: 2
  }
};
