/*
 * @Description:
 * @Date: 2021-03-24 23:53:29
 */
import { Props, IRules, IFormData } from '../types';

interface UseFormValuesContext {
  props: Props;
  rules: IRules;
  formData: IFormData;
  filteData: IFormData;
}
export default ({
  props,
  rules,
  formData,
  filteData
}: UseFormValuesContext) => {
  // 根据defaultValues设置设置默认数据
  const setDefaultValue = (prop: string, value: string | ArrayConstructor) => {
    value = props.defaultValues[prop];
    if (Array.isArray(value)) {
      return value.length ? value : [];
    }
    return value;
  };
  const initDefaultValue = (): void => {
    props.columns.forEach(({ prop, rule, value }) => {
      rules[prop] = rule;
      formData[prop] = setDefaultValue(prop, value);
    });
  };
  // 过滤掉数据为空字符串
  const getFilteValues = () => {
    const value: IFormData = {};
    Object.keys(formData).forEach(key => {
      if (
        formData[key] !== '' &&
        formData[key] !== undefined &&
        formData[key] != null
      ) {
        value[key] = formData[key];
      }
    });
    filteData = value;
    return filteData;
  };
  return { initDefaultValue, getFilteValues };
};
