/*
 * @Description:自然人信息管理table字段
 * @Date: 2023-04-23 13:14:55
 */
import { useTableColumn } from 'xrk-components';
export const index = useTableColumn({
  type: 'index',
  label: '序号',
  align: 'center'
});
export const fullName = useTableColumn({
  label: '姓名',
  prop: 'fullName'
});
export const phone = useTableColumn({
  label: '手机号',
  prop: 'phone'
});
export const cardNo = useTableColumn({
  label: '身份证号',
  prop: '_cardNo'
});
export const bankNo = useTableColumn({
  label: '银行卡号',
  prop: '_bankNo'
});
export const enterpriseName = useTableColumn({
  label: '客户企业',
  prop: 'enterpriseName'
});
export const projectName = useTableColumn({
  label: '项目名称',
  prop: 'projectName'
});
export const submitTaskSum = useTableColumn({
  label: '已提交任务量',
  prop: 'submitTaskTotalSum'
});
export const settledTasksSum = useTableColumn({
  label: '已结算任务量',
  prop: 'settlementSum'
});
export const passingTaskSum = useTableColumn({
  label: '待审核任务量',
  prop: 'passingTaskSum'
});
export const passTaskSum = useTableColumn({
  label: '审核通过任务量',
  prop: 'passTaskSum'
});
export const notPassTaskSum = useTableColumn({
  label: '审核未通过任务量',
  prop: 'notPassTaskSum'
});
export const invalidTaskSum = useTableColumn({
  label: '过期任务量',
  prop: 'invalidTaskSum'
});
export const userPrice = useTableColumn({
  label: '终端结算单价',
  prop: '_userPrice'
});
export const settledMoney = useTableColumn({
  label: '终端已结算金额',
  prop: '_settledMoney'
});
export const passingMoney = useTableColumn({
  label: '待审核任务量金额',
  prop: '_passingMoney'
});
