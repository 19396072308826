import { useInputColumn } from 'xrk-components';
export const fullNameSearch = useInputColumn({
  prop: 'fullName',
  label: '姓名：',
  type: 'input',
  config: {
    placeholder: '请输入姓名',
    clearable: true,
    maxlength: 30
  }
});
export const phoneSearch = useInputColumn({
  prop: 'phone',
  label: '手机号：',
  type: 'input',
  config: {
    type: 'tel',
    placeholder: '请输入手机号',
    clearable: true,
    maxlength: 11
  }
});
export const projectNameSearch = useInputColumn({
  prop: 'projectName',
  label: '项目名称：',
  type: 'input',
  config: {
    placeholder: '请输入项目名称',
    clearable: true,
    maxlength: 30
  }
});
export const customNameSearch = useInputColumn({
  prop: 'customName',
  label: '客户企业：',
  type: 'input',
  config: {
    placeholder: '请输入客户企业',
    clearable: true,
    maxlength: 30
  }
});
