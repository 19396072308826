export const ENTERPTISE_SIGNINGS_STATUS = new Map([
  [0, { label: '全部', value: '' }],
  [1, { label: '未签约', value: 0 }],
  [2, { label: '签约成功', value: 2 }],
  [3, { label: '签约失败', value: 3 }],
  [4, { label: '拒绝签约', value: 4 }]
]);
export const PERSON_STATUS = new Map([
  [0, { label: '全部', value: '' }],
  [1, { label: '未签约', value: 0 }],
  [2, { label: '签约成功', value: 2 }],
  [3, { label: '签约失败', value: 3 }]
]);
export const CONTRACT_SIGING_STATUS = new Map([
  [0, { label: '全部', value: '' }],
  [1, { label: '未完成', value: 3 }],
  [2, { label: '已完成', value: 2 }]
]);
