/*
 * @Description: 不通过弹层
 * @Date: 2021-10-29 11:34:50
 */

import TaskRecordNoPass from '../components/task-record-no-pass.vue';
import { ElMessageBox } from 'element-plus';
import { createDialog } from 'xrk-components';

/**
 * @description: 创建不通过弹层与弹层流程处理
 * @return {promise}
 */
export default function () {
  return new Promise(resolve => {
    createDialog({
      width: '800px',
      title: '',
      showClose: true,
      showFooter: false,
      contentRender: done => {
        async function onConfirm(e) {
          try {
            await ElMessageBox.confirm(
              `<p>是否确认不通过此条任务记录，请谨慎操作</p>`,
              {
                dangerouslyUseHTMLString: true
              }
            );
            done();
            resolve({ auditOpinion: e });
          } catch (e) {
            // reject(e);
          }
        }
        return (
          <TaskRecordNoPass
            done={done}
            onConfirm={e => {
              onConfirm(e);
            }}
          ></TaskRecordNoPass>
        );
      }
    });
  });
}
