/*
 * @Description:
 * @Date: 2023-03-22 15:00:55
 */
import { useTableColumn } from 'xrk-components';

const STEP_MAPPING = {
  1: {
    label: '表单迁移完成',
    color: '#FFA500'
  },
  2: {
    label: '迁移完成',
    color: '#00c48e'
  }
};
export const projectNoCol = useTableColumn({
  label: '项目编码',
  prop: 'projectNo'
});
export const projectNameCol = useTableColumn({
  label: '项目名称',
  prop: 'projectName'
});
export const serviceNameCol = useTableColumn({
  label: '服务项目/子项',
  prop: '_serviceName'
});
export const industryNameCol = useTableColumn({
  label: '发起方',
  prop: 'industryName'
});
export const csoNameCol = useTableColumn({
  label: '服务商',
  prop: 'csoName'
});
export const executeDateCol = useTableColumn({
  label: '执行周期',
  prop: 'executeDate'
});
export const targetProjectNamehCol = useTableColumn({
  label: '迁移到的项目',
  prop: 'targetProjectName'
});
export const transferRateCol = useTableColumn({
  label: '迁移进度',
  width: '140px',
  prop: 'transferRate',
  render({ cellValue }) {
    if (!cellValue) return <span>-</span>;
    const { label, color } = STEP_MAPPING[cellValue];
    return (
      <>
        <p>
          {cellValue}/2 <span style={{ color }}>{label}</span>
        </p>
      </>
    );
  }
});
export const transferDtCol = useTableColumn({
  label: '迁移时间',
  prop: '_transferDt'
});
