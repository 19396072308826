/*
 * @Description:
 * @Author: xinzeedu@163.com
 * @Date: 2023/4/20 下午3:41:37
 */
// import store from './store';
import { route } from './src/router';

const name = 'projectPersonnelList';

export { name, route };
