/*
 * @Description:
 * @Date: 2021-03-22 10:11:48
 */
/**
 * @description: 请求结果集
 */
export enum ResultEnum {
  SUCCESS = 1,
  ERROR = 0,
  TIMEOUT = 907,
  TYPE = 'success',
  SETPASSWORD = 927,
  LOGIN = -999,
  ENTERPRICE_NOT_AUTH = -1008,
  ENTERPRICE_AUTHING = -1010
}

/**
 * @description: 请求方法
 */
export enum RequestEnum {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

/**
 * @description:  常用的contentTyp类型
 */
export enum ContentTypeEnum {
  // json
  JSON = 'application/json;charset=UTF-8',
  // json
  TEXT = 'text/plain;charset=UTF-8',
  // form-data 一般配合qs
  FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
  // form-data  上传
  FORM_DATA = 'multipart/form-data;charset=UTF-8'
}
