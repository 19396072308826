/*
 * @Description:
 * @Date: 2023/4/26 下午4:07:12
 */
import { PeopleListData } from './types';
import { AUTH_STATUS, CONFIRM_STATUS } from '../constants';
import { date, money } from 'xrk-tools';
export const PeopleListTranslator = (result: Result<PeopleListData>) => {
  const {
    data: { list }
  } = result;
  list.forEach(item => {
    item._authStatus = AUTH_STATUS[item.authStatus].label;
    item._confirmStatus = CONFIRM_STATUS[item.confirmStatus].label;
    item._taskDt = item.taskDt ? date.dateFormat(+item.taskDt, 'Y-M-D') : '-';
    item._userPrice = money.moneyFixed(item.userPrice);
    item._userPriceTotal = money.moneyFixed(item.userPriceTotal);
  });
  return result;
};
