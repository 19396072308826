/*
 * @Description: API注册
 * @Date: 2021-09-26 15:02:05
 */
import http from '@/utils/http/axios';
import { QueryMainList, QueryProjectList, GetProjectInfo } from './types';
enum Api {
  queryMainList = '/project/queryMainList', // 项目列表
  getProjectInfo = '/project/getProjectInfo', // 获取项目详情
  queryProjectList = '/project/getList', // 查询项目列表
  updateProjectInfo = '/project/updateProjectInfo' // 修改项目信息
}

export const queryMainList: QueryMainList = async params =>
  http.request({
    url: Api.queryMainList,
    method: 'POST',
    params
  });

export const getProjectInfo: GetProjectInfo = async params =>
  http.request({
    url: Api.getProjectInfo,
    method: 'POST',
    params
  });

export const queryProjectList: QueryProjectList = async params =>
  http.request({
    url: Api.queryProjectList,
    method: 'GET',
    params
  });

export const updateProjectInfo = async params =>
  http.request({
    url: Api.updateProjectInfo,
    method: 'POST',
    params
  });
