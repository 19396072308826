/**
 * @Description:
 * @Date:2023-05-16 14:58:00
 */
import { date } from 'xrk-tools';
import { bannerListResult } from '@V/app-banner/src/data-source/types';
export const resultTranslatorsBanner = (
  result: Result<bannerListResult>
): Promise<Result<bannerListResult>> => {
  return new Promise(resolve => {
    result.data.list.forEach(item => {
      item['_createDt'] = date.dateFormat(item.createDt);
      item['_status'] = ['', '启用', '停用'][item.status];
    });
    resolve(result);
  });
};
