/*
 * @Description:
 * @Date: 2021-10-20 11:43:02
 */
import { markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import ProjectMigrationList from '../views/project-migration-list.vue';
import ProjectMigrationTable from '../views/project-migration-table.vue';
import ProjectMigrationData from '../views/project-migration-data.vue';

export const route = [
  {
    path: '/project-migration',
    name: 'project-migration',
    redirect: '/project-migration/project-migration-list',
    component: markRaw(RouterTransition),
    meta: {
      title: '项目迁移',
      isRelation: true
    },
    children: [
      {
        name: 'project-migration-list',
        meta: {
          title: '项目迁移'
        },
        component: ProjectMigrationList
      },
      {
        name: 'project-migration-table',
        meta: {
          title: '表单迁移'
        },
        component: ProjectMigrationTable
      },
      {
        name: 'project-migration-data',
        meta: {
          title: '数据迁移'
        },
        component: ProjectMigrationData
      }
    ]
  }
];
