/*
 * @Description: 输出格式
 * @Date: 2021-10-13 15:53:42
 */

export type Status = 0 | 1;
export type Message = string;
export type Type = string;
export type Data = any;

type Params = {
  status: Status;
  message?: Message;
  type: Type;
  data?: Data;
};

// 校验返回结构
export interface Output<T = Type> {
  status: Status;
  message: Message;
  type: T;
  data: Data;
}

// 校验器入参
type CreateOutput<T extends Output> = {
  (params: Params): T;
};

const defaultParams = {
  status: 0,
  message: '',
  type: '',
  data: null
};

const createOutput: CreateOutput<Output> = params => {
  const merge = { ...defaultParams, ...params };
  return merge;
};

export { createOutput };
