/*
 * @Description: 全局注册自定义组件
 * @Date: 2021-03-22 00:52:55
 */
import { App } from 'vue';

import { BaseTab as XrkTab } from '@/components/base/tab';
import { BaseSearch as XrkSearch } from '@/components/base/search';
import xrk from 'xrk-components';

export function setupGlobalComponents(app: App) {
  app.component('BaseTab', XrkTab);
  app.component('BaseSearch', XrkSearch);
  app.use(xrk, {
    table: { useBaseClass: false, border: true }
  });
}
