/*
 * @Description:
 * @Author: xinzeedu@163.com
 * @Date: 2023/4/27 下午6:50:45
 */
// import store from './store';
import { route } from './src/router';

const name = 'submitTask';

export { name, route };
