/*
 * @Description:
 * @Date: 2023/4/27 下午5:46:21
 */
//审核状态 0 未提交审核  1审核中 2审核通过 3审核未通过
export const AUDIT_STATUS = {
  0: { label: '未提交审核', value: 0 },
  1: { label: '审核中', value: 1 },
  2: { label: '审核通过', value: 2 },
  3: { label: '审核未通过', value: 3 }
};
export const AUDIT_STATUS_SEARCH = {
  1: { label: '审核中', value: 1 },
  2: { label: '审核通过', value: 2 },
  3: { label: '审核未通过', value: 3 }
};
//是否过期  0 未过期(默认)  1 已过期
export const IS_OVERDUE = {
  0: { label: '未过期', value: 0 },
  1: { label: '已过期', value: 1 }
};

//结算状态 0 未结算  1结算
export const SETTLEMENT_STATUS_SEARCH = {
  1: { label: '未结算', value: 1 },
  2: { label: '已结算', value: 2 }
};
//结算状态 0 未结算  1结算
export const SETTLEMENT_STATUS = {
  0: { label: '未结算', value: 0 },
  1: { label: '已结算', value: 1 }
};
//实名认证 状态
export const AUTH_STATUS = {
  0: { label: '未认证', value: 0 },
  1: { label: '认证中', value: 1 },
  2: { label: '认证成功', value: 2 },
  3: { label: '认证失败', value: 3 }
};
//是否停用&是否冻结
export const ENABLE_STATUS = {
  1: { label: '否', value: 1 },
  2: { label: '是', value: 2 }
};
