/*
 * @Description:
 * @Date: 2023-02-13 09:50:01
 */
import http from '@/utils/http/axios';
import {
  SaveTransferProject,
  TransferList,
  FormCheck,
  FormTransfer,
  DataTransfer,
  GetFormCheckItem,
  GetDataCheckItem,
  TransferProjectInfo
} from './types';
import {
  transferListTranslator,
  transferProjectInfoTranslator,
  formCheckTranslator
} from './translators';

enum Api {
  saveTransferProject = '/transfer/saveTransferProject', // 新增项目迁移记录
  transferList = '/transfer/transferList', // 项目迁移列表
  transferProjectInfo = '/transfer/transferProjectInfo', // 项目迁移明细
  formCheck = '/transfer/formCheck', // 表单检查
  formTransfer = '/transfer/formTransfer', // 表单迁移
  dataTransfer = '/transfer/dataTransfer', // 项目数据迁移
  getFormCheckItem = '/transfer/getFormCheckItem', // 获取表单校验信息
  getDataCheckItem = '/transfer/getDataCheckItem' // 获取数据校验信息
}

export const saveTransferProject: SaveTransferProject = async params =>
  http.request({
    url: Api.saveTransferProject,
    method: 'POST',
    params
  });

export const transferList: TransferList = async params => {
  const resp = await http.request({
    url: Api.transferList,
    method: 'POST',
    params
  });
  return transferListTranslator(resp);
};
export const transferProjectInfo: TransferProjectInfo = async params => {
  const resp = await http.request({
    url: Api.transferProjectInfo,
    method: 'POST',
    params
  });
  return transferProjectInfoTranslator(resp);
};
export const formCheck: FormCheck = async params => {
  const resp = await http.request({
    url: Api.formCheck,
    method: 'POST',
    params
  });
  return formCheckTranslator(resp);
};
export const formTransfer: FormTransfer = async params =>
  http.request({
    url: Api.formTransfer,
    method: 'POST',
    params
  });
export const dataTransfer: DataTransfer = async params =>
  http.request({
    url: Api.dataTransfer,
    method: 'POST',
    params
  });
export const getFormCheckItem: GetFormCheckItem = async params => {
  const resp = await http.request({
    url: Api.getFormCheckItem,
    method: 'POST',
    params
  });
  return formCheckTranslator(resp);
};
export const getDataCheckItem: GetDataCheckItem = async params =>
  http.request({
    url: Api.getDataCheckItem,
    method: 'POST',
    params
  });
