/*
 * @Description:
 * @Date: 2022-03-14 19:18:12
 */

import { reactive } from 'vue';
import { defineStore } from 'pinia';
// import { storage } from '@/utils/storage';
import { TOKEN_KEY } from '@/config/index';
import UserEntity from '@domains/user/index';
import { useCreateModal } from 'xrk-components';
import { UserUpdatePassword as updatePasswordComponent } from '@/views/user/app';
import UserImprovePassword from '@/views/user/src/components/user-Improve-password.vue';
import router from '@/router';
import { getUserInfo } from '@/common/data-source/user/apis';

const name = 'useUserStoreSetup';
const useSetup = defineStore(
  name,
  () => {
    const info = reactive({
      token: '',
      menus: [],
      user: {
        firstResetPwd: null
      },
      enterprise: {}
    });
    function setInfo(data) {
      // const { token, user, enterprise } = data;
      Object.assign(info, data);
      const token = data.token;
      localStorage.setItem(TOKEN_KEY, token);
      //首次登录重置密码
      if (+info.user.firstResetPwd === 1) {
        useCreateModal(UserImprovePassword, { info });
      }
    }
    async function logout(query = {}) {
      UserEntity.prototype.logout().finally(() => {
        clearUser(query);
      });
    }
    function clearUser(query = {}) {
      info.token = '';
      localStorage.clear();
      router.replace({
        path: '/user/login',
        query
      });
      location.reload();
    }
    function updatePassword() {
      useCreateModal(updatePasswordComponent);
    }
    async function syncUser() {
      const { data } = await getUserInfo();
      setInfo(data);
    }
    return { info, setInfo, logout, updatePassword, clearUser, syncUser };
  },
  {
    persist: true
  }
);

export { useSetup, name };
