/**
 * @Description: 其他功能api
 * @Date:2024-04-21 14:58:00
 */
import http from '@/utils/http/axios';
import {
  bannerAddBannerParams,
  // bannerAddBannerResult,
  bannerListParams,
  bannerListResult,
  bannerStartAndstopParams,
  bannerStartAndstopResult
} from '@V/app-banner/src/data-source/types';
import { resultTranslatorsBanner } from '@V/app-banner/src/data-source/translators';

enum Api {
  bannerAddBanner = 'banner/addBanner', //新增轮播图
  commonUpload = 'common/upload', //公用的上传图片
  bannerList = 'banner/list', //轮播图列表
  bannerUpdateStatus = 'banner/updateStatus' //轮播图启停
}

export const autoUploadImge: (params) => Promise<Result> = async params => {
  console.log('paramsApi', params);
  const respond = await http.uploadFile(
    {
      url: Api.commonUpload,
      method: 'POST'
    },
    params
  );
  return respond as unknown as Result;
};
//自动上传
// export const autoUploadImge: () => Promise<bannerAddBannerResult> =
//   async () => {
//     const respond = await http.request<bannerAddBannerResult>({
//       url: Api.commonUpload,
//       method: 'POST'
//     });
//     return respond;
//   };

//新增轮播图
export const bannerAddBannerApi: (
  params: bannerAddBannerParams
) => Promise<Result> = async params => {
  const respond = await http.request<Result>({
    url: Api.bannerAddBanner,
    method: 'POST',
    params
  });
  return respond;
};
//轮播图列表
export const bannerListApi: (
  params: bannerListParams
) => Promise<Result<bannerListResult>> = async params => {
  const respond = await http.request<Result<bannerListResult>>({
    url: Api.bannerList,
    method: 'GET',
    params
  });
  return resultTranslatorsBanner(respond);
};
//启/停轮播图
export const bannerStartAndstopApi: (
  params: bannerStartAndstopParams
) => Promise<bannerStartAndstopResult> = async params => {
  const respond = await http.request<bannerStartAndstopResult>({
    url: Api.bannerUpdateStatus,
    method: 'POST',
    params
  });
  return respond;
};
