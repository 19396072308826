interface OpenNewWin {
  id: number;
  [id: number]: Window;
  win?: Window;
  strWinFeatures: string;
  strWindowFeatures: string;
}
class OpenNewWin {
  static windows = {}; // 实例win集合
  constructor() {
    // if (new.target !== OpenNewWin) {
    //   throw new Error('本类未实例化');
    // }
    this.id = Date.now();
    // 实例
    this.win = null;
    // 配置项
    this.strWinFeatures =
      'toolbar=no, location=no,titlebar=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=yes';
    this.setWidthHeight();
  }
  setWidthHeight() {
    const cw = Math.max(document.documentElement.clientWidth * 0.8, 1200);
    const ch = Math.max(document.documentElement.clientHeight * 0.9, 500);
    const left = document.documentElement.clientWidth * 0.1;
    const top = document.documentElement.clientWidth * 0.05;
    this.strWindowFeatures += `,height=${ch},width=${cw},left=${left},top=${top}`;
  }
  // 打开新窗口 并添加
  createWin(url = '') {
    this.win = window.open(url, '_blank', this.strWindowFeatures);
    OpenNewWin.windows[this.id] = this.win;
  }
  // 设置窗口URL
  setUrl(url) {
    console.log(OpenNewWin.windows);
    if (!this.win) throw new Error('未初始化');
    this.win.location = url;
  }
  // 关闭当前窗口
  close() {
    if (this.win) {
      this.win.close();
      this.win = null;
      delete OpenNewWin.windows[this.id];
    }
  }
  // 清除所有win
  closeAll() {
    Object.keys(OpenNewWin.windows).forEach(item => {
      OpenNewWin.windows[item].close();
    });
    OpenNewWin.windows = {};
  }
}
export default OpenNewWin;
