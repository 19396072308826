/*
 * @Description:
 * @Date: 2023/4/27 下午5:46:21
 */
import { TaskRecordListData, SummaryListData } from './types';
import {
  AUTH_STATUS,
  AUDIT_STATUS,
  IS_OVERDUE,
  SETTLEMENT_STATUS,
  ENABLE_STATUS
} from '../constants';
import { date } from 'xrk-tools';
export const taskRecordListTranslator = (
  result: Result<TaskRecordListData>
) => {
  const {
    data: { list }
  } = result;
  list.forEach(item => {
    item._finishDt = date.dateFormat(item.finishDt);
    item._auditStatus = AUDIT_STATUS[item.auditStatus].label;
    item._isOverdue = IS_OVERDUE[item.isOverdue].label;
    item._settlementStatus = SETTLEMENT_STATUS[item.settlementStatus].label;
  });
  return result;
};

export const summaryListTranslator = (result: Result<SummaryListData>) => {
  const {
    data: { list }
  } = result;
  list.forEach(item => {
    item._authStatus = AUTH_STATUS[item.authStatus].label;
    item._taskSum = item.taskSum ?? '-';
    item._status = ENABLE_STATUS[item.status].label;
    item._enableStatus = ENABLE_STATUS[item.enableStatus].label;
  });
  return result;
};

export const projectDetailTranslator = result => {
  const { data } = result;
  let timeValue = date.dateFormat(data.startDt, 'Y-M-D H:m');
  if (data.isSetTime && +data.timeType === 1) {
    timeValue = `${date.dateFormat(
      data.startDt,
      'Y-M-D H:m'
    )}—${date.dateFormat(data.endDt, 'Y-M-D H:m')}`;
  }
  data.targetTopcs = [
    //目标题
    data.isSetTarget && {
      title: data.targetTitle,
      value: data.customerInfo,
      type: '-1',
      isMustFill: true,
      remark: data.targetRemark
    },
    //时间段题
    data.isSetTime && {
      title: data.timeTitle,
      value: timeValue,
      type: '-2',
      isMustFill: true,
      remark: data.timeRemark
    },
    //定位题
    data.isSetLocation && {
      title: data.locationTitle,
      value: data.addressPicUrl,
      type: '-3',
      isMustFill: true,
      remark: data.locationRemark
    }
  ].filter(item => item);

  return result;
};
