/*
 * @Description:
 * @Date: 2023-03-27 11:10:22
 */
export const FIELD_STATUS_STATUS_DEFAULT = 0; // 字段未检查
export const FIELD_STATUS_STATUS_SUCCESS = 1; // 字段检查成功
export const FIELD_STATUS_STATUS_ERROR = 2; // 字段检查失败

export const TRANSFER_STATUS_DEFAULT = 0; // 项目迁移默认状态
export const TRANSFER_TABLE_STATUS_SUCCESS = 1; // 表单迁移成功
export const TRANSFER_DATA_STATUS_SUCCESS = 2; // 数据迁移成功
