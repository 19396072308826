/*
 * @Description:
 * @Date: 2023-4-21 13:29:02
 */
import { markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import downloadCenterList from '../views/download-center-list.vue';

export const route = [
  {
    path: '/download-center',
    name: 'download-center',
    redirect: '/download-center/download-center-list',
    component: markRaw(RouterTransition),
    meta: {
      title: '下载中心',
      isRelation: true
    },
    children: [
      {
        name: 'download-center-list',
        meta: {
          title: '下载中心'
        },
        component: downloadCenterList
      }
    ]
  }
];
