/*
 * @Description:
 * @Date: 2023-03-27 11:10:22
 */
// 支付状态
export const PAY_STATUS = {
  0: { label: '未支付', value: 0 },
  1: { label: '支付中', value: 1 },
  2: { label: '已支付', value: 2 }
};
