/*
 * @Description:
 * @Date: 2023-03-22 15:34:38
 */
import {
  useInputColumn,
  useDatePickerColumn,
  useSelectColumn
} from 'xrk-components';
import { PAY_STATUS } from '../constants';

export const projectNameSearch = useInputColumn({
  prop: 'projectName',
  label: '项目名称：',
  type: 'input',
  config: {
    placeholder: '请输入项目名称',
    clearable: true,
    maxlength: 30
  }
});
export const orderNoSearch = useInputColumn({
  prop: 'orderNo',
  label: '订单编号：',
  type: 'input',
  config: {
    placeholder: '请输入订单编号',
    clearable: true,
    maxlength: 30
  }
});
export const submitDateSearch = useDatePickerColumn({
  prop: '__beginDateRange',
  label: '订单生成时间：',
  config: {
    clearable: true,
    type: 'daterange',
    startPlaceholder: '订单生成时间起',
    endPlaceholder: '订单生成时间止'
  },
  listener: {
    onChange: (value, searchObj) => {
      // clearble 默认为 true，但是点击后并不会清除值
      if (value == null) {
        searchObj.createDtStart = '';
        searchObj.createDtEnd = '';
      }
      searchObj.createDtStart = value[0];
      searchObj.createDtEnd = value[1];
      return;
    }
  }
});
export const payStatusSearch = useSelectColumn({
  prop: 'payStatus',
  label: '支付状态：',
  config: {
    placeholder: '请选择支付状态',
    clearable: true,
    options: PAY_STATUS
  }
});
