/*
 * @Description:
 * @Date: 2023/4/27 下午5:46:21
 */
import http from '@/utils/http/axios';
import { REQUEST_PATH } from '@/config';

import {
  ListUnSubmitTaskParams,
  ListUnSubmitTaskData,
  ListSubmitTaskRecordParams,
  ListSubmitTaskRecordData,
  SettlementListParams,
  SettlementListData,
  ImportUserTaskPageData,
  SelectSubmitTaskPageParams,
  SelectSubmitTaskPageData,
  TotalSubmitTaskData
} from './types';
import {
  listSubmitTaskRecordTransLate,
  listUnSubmitTaskTransLate
} from './translators';
enum Api {
  listUnSubmitTask = '/submitTask/listUnSubmitTask', // 待提交任务列表
  listSubmitTaskRecord = '/taskRecord/listSubmitTaskRecord', //提交记录列表
  settlementList = '/taskRecord/view', //点击结算数据的列表（任务记录）
  importUserTaskPage = '/submitTask/importUserTaskPage', //自动提交-分页查询导入数据
  importUserTask = '/submitTask/importUserTask', //自动提交-导入任务量
  importDelUserTask = '/submitTask/importDelUserTask', //自动提交-删除导入人员
  dataPackage = '/taskRecord/dataPackage', //提交记录-数据包-(1-数据报表 2-任务记录)
  shwoSign = '/taskSettlement/shwoSign', //提交记录-电子结算单-查看 返回url链接
  downloadSettlement = '/taskSettlement/download', //提交记录-电子结算单-下载
  recallSettlement = '/taskSettlement/recall', //提交记录-电子结算单-撤回结算单
  signSettlement = '/taskSettlement/sign', //提交记录-电子结算单-签署
  selectUser = '/submitTask/selectUser', //[手动提交]提交任务 - 选择提交人员
  selectSubmitTaskPage = '/submitTask/selectSubmitTaskPage', //分页提交任务列表(可以填写任务量）
  delSubmitTaskUser = '/submitTask/delSubmitTaskUser', //提交任务列表-移除人员功能
  modifySubmitTaskNum = '/submitTask/modifySubmitTaskNum', //提交任务 -修改本次提交任务量
  totalSubmitTaskNum = '/submitTask/totalSubmitTaskNum', //提交任务 -继续提交-统计信息
  importantNote = '/submitTask/importantNote', //提交任务 -重要提示(下一步限额提示)
  generate = '/taskSettlement/generate', //生成结算单
  checkSubmitTask = '/submitTask/checkSubmitTask', //自动提交-点击继续导入
  submitTaskDownload = '/submitTask/download' //自动提交-下载Excel
}

export const listUnSubmitTask: (
  params: ListUnSubmitTaskParams
) => GApiResult<ListUnSubmitTaskData> = async params => {
  const respond = await http.request({
    url: Api.listUnSubmitTask,
    method: 'POST',
    params
  });
  return listUnSubmitTaskTransLate(respond);
};

export const listSubmitTaskRecord: (
  params: ListSubmitTaskRecordParams
) => GApiResult<ListSubmitTaskRecordData> = async params => {
  const respond = await http.request({
    url: Api.listSubmitTaskRecord,
    method: 'POST',
    params
  });
  return listSubmitTaskRecordTransLate(respond);
};

export const settlementList: (
  params: SettlementListParams
) => GApiResult<SettlementListData> = async params => {
  const respond = await http.request({
    url: Api.settlementList,
    method: 'POST',
    params
  });
  return respond;
};

export const importUserTaskPage: (params: {
  importCacheKey: string;
}) => GApiResult<ImportUserTaskPageData> = async params => {
  const respond = await http.request({
    url: Api.importUserTaskPage,
    method: 'POST',
    params
  });
  return respond;
};

export const importUserTask: (params) => Promise<Result> = async params => {
  const respond = await http.uploadFile(
    {
      url: Api.importUserTask,
      method: 'POST'
    },
    params
  );
  return respond as unknown as Result;
};

export const importDelUserTask: (params: {
  importCacheKey: string;
  userName: string;
  phone: string;
}) => GApiResult<Result> = async params => {
  const respond = await http.request({
    url: Api.importDelUserTask,
    method: 'POST',
    params
  });
  return respond;
};

export const dataPackage: (params: {
  projectId: string | number;
  settlementId: string; //结算单ID
  type: 1 | 2; //1-数据报表 2-任务记录
}) => GApiResult<Result> = async params => {
  const respond = await http.request({
    url: Api.dataPackage,
    method: 'POST',
    params
  });
  return respond;
};

export const shwoSign: (params: {
  projectId: string | number;
  settlementId: string; //结算单ID
}) => GApiResult<{ signUrl: string }> = async params => {
  const respond = await http.request({
    url: Api.shwoSign,
    method: 'POST',
    params
  });
  return respond;
};

export const signSettlement: (params: {
  projectId: string | number;
  settlementId: string | number; //结算单ID
}) => GApiResult<{ signUrl: string }> = async params => {
  const respond = await http.request({
    url: Api.signSettlement,
    method: 'POST',
    params
  });
  return respond;
};

export const recallSettlement: (params: {
  projectId: string | number;
  settlementId: string; //结算单ID
}) => GApiResult<Result> = async params => {
  const respond = await http.request(
    {
      url: Api.recallSettlement,
      method: 'POST',
      params
    },
    {
      isShowSuccessMessage: true
    }
  );
  return respond;
};

export const downloadSettlement: (params: {
  projectId: string | number;
  settlementId: number; //结算单ID
}) => GApiResult<{
  signUrl: string;
}> = async params => {
  const respond = await http.request({
    url: Api.downloadSettlement,
    method: 'GET',
    params
  });
  return respond;
};

export const selectUser: (params: {
  projectId: string | number;
  userJson: string; //已选项
}) => GApiResult<{
  cacheKey: string;
}> = async params => {
  const respond = await http.request({
    url: Api.selectUser,
    method: 'POST',
    params
  });
  return respond;
};

export const selectSubmitTaskPage: (
  params: SelectSubmitTaskPageParams
) => GApiResult<SelectSubmitTaskPageData> = async params => {
  const respond = await http.request({
    url: Api.selectSubmitTaskPage,
    method: 'POST',
    params
  });
  return respond;
};

export const delSubmitTaskUser: (params: {
  cacheKey: string;
  userId: string; //移除人员用户id
}) => GApiResult<Result> = async params => {
  const respond = await http.request({
    url: Api.delSubmitTaskUser,
    method: 'POST',
    params
  });
  return respond;
};

export const modifySubmitTaskNum: (params: {
  submitTaskNum: string;
  cacheKey: string;
  userId: string; //用户ID
}) => GApiResult<Result> = async params => {
  const respond = await http.request({
    url: Api.modifySubmitTaskNum,
    method: 'POST',
    params
  });
  return respond;
};

export const totalSubmitTaskNum: (params: {
  cacheKey: string;
  projectId: string | number;
}) => GApiResult<TotalSubmitTaskData> = async params => {
  const respond = await http.request({
    url: Api.totalSubmitTaskNum,
    method: 'POST',
    params
  });
  return respond;
};

export const importantNote: (params: {
  cacheKey: string;
  projectId: string | number;
}) => GApiResult<{
  quotaPeoples: number;
}> = async params => {
  const respond = await http.request({
    url: Api.importantNote,
    method: 'POST',
    params
  });
  return respond;
};

export const generate: (params: {
  cacheKey: string;
  projectId: string | number;
}) => GApiResult<{
  id: number;
  projectId: number;
  errCode?: string;
  errMsg?: string;
}> = async params => {
  const respond = await http.request({
    url: Api.generate,
    method: 'POST',
    params
  });
  return respond;
};

export const checkSubmitTask: (params: {
  importCacheKey: string;
  projectId: string | number;
}) => GApiResult<{
  cacheKey: string;
}> = async params => {
  const respond = await http.request({
    url: Api.checkSubmitTask,
    method: 'POST',
    params
  });
  return respond;
};

export const submitTaskDownload = (params: { importCacheKey: string }) => {
  return `${REQUEST_PATH}${Api.submitTaskDownload}?importCacheKey=${params.importCacheKey}`;
};
//导入提交任务模板
export const downloadSubmitTaskTemp = `https://hwk-date.oss-cn-beijing.aliyuncs.com/WEB/template/提交任务-数据模板.xls`;
