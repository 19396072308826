/**
 * @Description: 下载中心的接口
 * @Date:2024-04-21 14:58:00
 */
import http from '@/utils/http/axios';
import { taskDownloadListParams, taskDownloadListData } from './types';
import { resultTranslatorsDownload } from './translators';

enum Api {
  downlist = 'down/list' //下载中心的列表
}
export const taskDownloadListApi: (
  params: taskDownloadListParams
) => Promise<Result<taskDownloadListData>> = async params => {
  const respond = await http.request<Result<taskDownloadListData>>({
    url: Api.downlist,
    method: 'POST',
    params
  });
  return resultTranslatorsDownload(respond);
};
