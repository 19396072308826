/*
 * @Description:
 * @Date: 2021-09-15 14:35:55
 */
// @ts-nocheck
import './public-path';
import { createApp } from 'vue';
import App from './App.vue';
// import store from './store'; // 需在router之前先引入，否则store中require.context会出问题：fn(path)可以获得，但里面的属性无法获取。
import { setupStore } from './store';
import router, { setupRouter } from './router';
import { setupGlobalComponents } from '@/plugins';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import './assets/css/reset.css';
import 'xrk-components/lib/index.css';
import { DOMAIN } from './config';

const app = createApp(App);

app.use(ElementPlus, { locale, size: 'default' });

setupStore(app);

setupRouter(app);

setupGlobalComponents(app);

router.isReady().then(() => app.mount('#app'));

document.domain = DOMAIN;

window.rootV3Instance = app;
