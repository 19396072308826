/*
 * @Description:
 * @Date: 2023-02-13 09:50:01
 */
import http from '@/utils/http/axios';
import { SettlementList, WaitPayDetail } from './types';
import {
  settlementListTranslator,
  waitPayDetailTranslator
} from './translators';

enum Api {
  settlementList = '/settlement/list', // 项目结算管理列表
  waitPayListList = '/settlement/waitPayList', // 项目结算管理列表
  waitPayDetail = '/settlement/waitPayDetail', // 项目结算管理列表
  doOrder = '/settlement/doOrder' // 项目结算管理-生成订单
}
export const settlementList: SettlementList = async params => {
  const resp = await http.request({
    url: Api.settlementList,
    method: 'POST',
    params
  });
  return settlementListTranslator(resp);
};

export const waitPayListList: SettlementList = async params => {
  const resp = await http.request({
    url: Api.waitPayListList,
    method: 'POST',
    params
  });
  return settlementListTranslator(resp);
};

export const waitPayDetail: WaitPayDetail = async params => {
  const resp = await http.request({
    url: Api.waitPayDetail,
    method: 'POST',
    params
  });
  return waitPayDetailTranslator(resp);
};

export const doOrder: (params: {
  settlementIds: string;
}) => GApiResult<GApiResult> = async params => {
  const resp = await http.request({
    url: Api.doOrder,
    method: 'POST',
    params
  });
  return resp;
};
