/*
 * @Description:
 * @Date: 2021-10-20 11:43:02
 */
import userLogin from '../views/user-login.vue';

export const route = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      noLayout: true
    },
    component: userLogin
  }
];
