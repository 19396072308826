/*
 * @Description: 文件预览
 * @Date: 2023-05-18 16:26:22
 */

const fileTypeReg = new RegExp(
  /\.pdf|\.doc|\.dot|\.docx|\.dotx|\.xlc|\.xlm|\.xls|\.xlt|\.xlw|\.xlsx|\.xltx|\.xlsm|\.xlsb|\.ppt|\.pptx/,
  'i'
);
const imageTypeReg = new RegExp(/\.bmp|\.jpg|\.png|\.gif|\.jpeg/, 'i');
const zipTypeReg = new RegExp(
  /\.rar|\.cab|\.arj|\.lzh|\.ace|\.7-zip|\.tar|\.gzip|\.uue|\.bz2|\.jar|\.iso|\.z/,
  'i'
);

export const getFileType = url => {
  if (typeof url === 'string') {
    if (imageTypeReg.test(url)) {
      return 'image';
    }
    if (fileTypeReg.test(url)) {
      return 'file';
    }
    if (zipTypeReg.test(url)) {
      return 'zip';
    }
  }

  return '';
};

//将详情接口内题目的图片汇总成一个list
export const getPicList = (list, addressPicUrl) => {
  const arr = [];
  list.forEach(item => {
    if ([13, 20].includes(item.type)) {
      if (item.answers.length > 0) {
        item.answers.forEach(answer => {
          const value = answer.answer[0].url;
          const fileType = getFileType(value);
          fileType === 'image' && arr.push(value);
        });
      }
    }
    if ([16].includes(item.type)) {
      if (item.answers.length > 0) {
        item.answers.forEach(answer => {
          const value = answer.answer[0].addressPicUrl;
          const fileType = getFileType(value);
          fileType === 'image' && arr.push(value);
        });
      }
    }
  });
  addressPicUrl && arr.unshift(addressPicUrl);
  return arr;
};
