import { DefineComponent } from 'vue';
import appBannerAddModelValue from './app-banner-add-model.vue';
import { BaseUploadProps } from 'xrk-components';
export type FormDataProps = {
  bannerName: any;
  fileList: BaseUploadProps['fileList'];
  imgUrl: string;
};
export const AppBannerAddModel =
  appBannerAddModelValue as unknown as DefineComponent<{
    onLoading: (status: boolean) => void;
  }>;
export type AppBannerAddModeRef = {
  sendBannerData: () => Promise<FormDataProps>;
};
