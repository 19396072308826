/*
 * @Description:
 * @Date: 2021-12-30 16:35:32
 */
import {
  BaseTypes,
  MakeSceneBaseTypes
} from '@/views/questionnaire-mgmt/src/data-source/types';

const MAKE_SCENE_TARGET_TYPE = -1;
const MAKE_SCENE_TIME_TYPE = -2;
const MAKE_SCENE_LOCATION_TYPE = -3;

const CONFIGURABLE_TARGET_TYPES = {
  1: {
    value: 1,
    label: '等级医院医生',
    icon: require('@/views/questionnaire-mgmt/src/assets/images/dengji@2x.png'),
    hover: 'C端映射字段为姓名、医院、医院等级、科室、职称、省市区'
  },
  2: {
    value: 2,
    label: '基层医疗机构医生',
    icon: require('@/views/questionnaire-mgmt/src/assets/images/jicheng@2x.png'),
    hover: 'C端映射字段为姓名、医疗机构名称、医疗机构类别、医疗机构地区、科室'
  },
  3: {
    value: 3,
    label: '零售药店客户',
    icon: require('@/views/questionnaire-mgmt/src/assets/images/lingshou@2x.png'),
    hover: 'C端映射字段为姓名、职务、药店名称、省市区、详细地址'
  },
  4: {
    value: 4,
    label: '患者',
    icon: require('@/views/questionnaire-mgmt/src/assets/images/huanzhe@2x.png'),
    hover: 'C端映射字段为姓名、性别、出生年月'
  },
  5: {
    value: 5,
    label: '机构',
    icon: require('@/views/questionnaire-mgmt/src/assets/images/jigou@2x.png'),
    hover: 'C端映射字段为机构名称、省市区、详细地址'
  },
  6: {
    value: 6,
    label: '机构客户',
    icon: require('@/views/questionnaire-mgmt/src/assets/images/jigou2@2x.png'),
    hover: 'C端映射字段为姓名、机构名称、省市区、详细地址'
  }
};

const CONFIGURABLE_TIME_TYPES = {
  1: {
    label: '时间段题',
    value: 1,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/shijian@2x.png')
  },
  2: {
    label: '时刻题',
    value: 2,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/shijian@2x.png')
  }
};

const CONFIGURABLE_LOCATION_TYPES = {
  1: {
    label: '定位题',
    value: 1,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/dingwei@2x.png')
  }
};

const CONFIGURABLE_MAKE_SCENE_BASE_TYPES = {
  [MAKE_SCENE_TARGET_TYPE]: {
    label: '目标题',
    value: MAKE_SCENE_TARGET_TYPE
  },
  [MAKE_SCENE_TIME_TYPE]: {
    label: '时间段题',
    value: MAKE_SCENE_TIME_TYPE
  },
  [MAKE_SCENE_LOCATION_TYPE]: {
    label: '定位题',
    value: MAKE_SCENE_LOCATION_TYPE
  }
};

const CONFIGURABLE_BASE_TYPES = {
  1: {
    label: '单选题',
    value: 1,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/quanquan@2x.png')
  },
  2: {
    label: '多选题',
    value: 2,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/CheckBox@2x.png')
  },
  3: {
    label: '单选投票题',
    value: 3,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/quanquan@2x.png')
  },
  4: {
    label: '单项填空题',
    value: 4,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/danxuan@2x.png')
  },
  5: {
    label: '多项填空题',
    value: 5,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/duoxuan@2x.png')
  },
  6: {
    label: '打分题',
    value: 6,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/xingxing@2x.png')
  },
  7: {
    label: '地址',
    value: 7
  },
  8: {
    label: '上传附件',
    value: 8,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/huizheng@2x.png')
  },
  9: {
    label: '上传pdf',
    value: 9,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/huizheng@2x.png')
  },
  10: {
    label: '上传word',
    value: 10,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/huizheng@2x.png')
  },
  11: {
    label: '上传excel',
    value: 11,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/huizheng@2x.png')
  },
  12: {
    label: '上传ppt',
    value: 12,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/huizheng@2x.png')
  },
  13: {
    label: '上传图片',
    value: 13,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/huizheng@2x.png')
  },
  14: {
    label: '时间题',
    value: 14,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/shijian@2x.png')
  },
  15: {
    label: '月度题',
    value: 15,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/shijian@2x.png')
  },
  16: {
    label: '定位题',
    value: 16,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/dingwei@2x.png')
  },
  17: {
    label: '描述题',
    value: 17,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/danxuan@2x.png')
  },
  18: {
    label: '描述题',
    value: 18,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/danxuan@2x.png')
  },
  19: {
    label: '数字填空题',
    value: 19,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/duoxuan@2x.png')
  },
  20: {
    label: '签名题',
    value: 20,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/danxuan@2x.png')
  },
  21: {
    label: '签到题', // 未使用
    value: 21,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/danxuan@2x.png')
  },
  22: {
    label: '姓名填空题',
    value: 22,
    icon: require('@/views/questionnaire-mgmt/src/assets/images/danxuan@2x.png')
  }
};

// 基础题型
const CONFIGURABLE_TYPES = {
  ...CONFIGURABLE_MAKE_SCENE_BASE_TYPES,
  ...CONFIGURABLE_BASE_TYPES
};

// 分类题型
const CONFIGURABLE_CLASSIFY: Array<{
  label: string;
  values: Array<{
    label: string;
    value: BaseTypes;
    rootType?: MakeSceneBaseTypes;
    icon: string;
    hover?: string;
  }>;
}> = [
  {
    label: '目标题',
    values: Object.values(CONFIGURABLE_TARGET_TYPES).map(value => ({
      ...value,
      rootType: '-1'
    }))
  },
  {
    label: '时间题',
    values: Object.values(CONFIGURABLE_TIME_TYPES).map(value => ({
      ...value,
      rootType: '-2'
    }))
  },
  {
    label: '定位题',
    values: Object.values(CONFIGURABLE_LOCATION_TYPES).map(value => ({
      ...value,
      rootType: '-3'
    }))
  },
  {
    label: '选择题',
    values: [1, 2, 3].map(type => CONFIGURABLE_TYPES[type])
  },
  {
    label: '填空题',
    values: [4, 5, 19, 22].map(type => CONFIGURABLE_TYPES[type])
  },
  { label: '打分题', values: [6].map(type => CONFIGURABLE_TYPES[type]) },
  {
    label: '个人信息题',
    values: [
      {
        label: '姓名',
        value: 4,
        icon: require('@/views/questionnaire-mgmt/src/assets/images/xingming@2x.png')
      },
      {
        label: '手机',
        value: 4,
        icon: require('@/views/questionnaire-mgmt/src/assets/images/shouji@2x.png')
      },
      {
        label: '邮箱',
        value: 4,
        icon: require('@/views/questionnaire-mgmt/src/assets/images/youxiang@2x.png')
      },
      ...[20].map(type => CONFIGURABLE_TYPES[type])
    ]
  },
  {
    label: '附件上传',
    values: [9, 10, 11, 12, 13].map(type => CONFIGURABLE_TYPES[type])
  },
  {
    label: '信息提交',
    values: [14, 15, 16].map(type => CONFIGURABLE_TYPES[type])
  },
  {
    label: '描述',
    values: [17, 18].map(type => CONFIGURABLE_TYPES[type])
  }
];

const MAKE_SCENE_HIDE_TYPES = [16, 17];
const QUESTIONNAIRE_HIDE_TYPES = [-1, -2, -3, 18];

export {
  CONFIGURABLE_TARGET_TYPES,
  CONFIGURABLE_TIME_TYPES,
  CONFIGURABLE_LOCATION_TYPES,
  CONFIGURABLE_MAKE_SCENE_BASE_TYPES,
  CONFIGURABLE_BASE_TYPES,
  CONFIGURABLE_TYPES,
  CONFIGURABLE_CLASSIFY,
  MAKE_SCENE_TARGET_TYPE,
  MAKE_SCENE_TIME_TYPE,
  MAKE_SCENE_LOCATION_TYPE,
  MAKE_SCENE_HIDE_TYPES,
  QUESTIONNAIRE_HIDE_TYPES
};
