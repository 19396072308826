
import { check } from 'xrk-tools';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'remote-select',
  emits: ['change', 'update'],
  props: {
    clearable: { type: Boolean, default: false },
    useLabel: { type: Boolean, default: true },
    useNameKey: { type: Boolean, default: false },
    placeholder: { type: String, default: '请输入' },
    searchKey: { type: String, default: 'value' },
    remoteMethod: {
      type: Function,
      default: () => {
        return;
      }
    },
    remoteProp: {
      type: Object,
      default: () => ({ codeKey: 'code', nameKey: 'name' })
    }
  },
  setup(props, { emit }) {
    const remoteSelect = ref<any>(null);
    const loading = ref(false);
    const options = ref([]);
    return {
      remoteSelect,
      loading,
      options,
      change(value: any) {
        const obj =
          options.value.find(
            item =>
              item[
                props.useNameKey
                  ? props.remoteProp.nameKey
                  : props.remoteProp.codeKey
              ] == value
          ) || {};
        emit('change', obj);
        setTimeout(() => {
          options.value = [];
          remoteSelect.value.blur();
        }, 0);
      },
      clear() {
        options.value = [];
      },
      remoteMethodFn: async (value: any) => {
        if (check.isFalseValue(value)) {
          options.value = [];
          loading.value = false;
          return;
        }
        // const time = new Date().getTime();
        // options.value = [
        //   {
        //     entripseName: "企业1234" + time,
        //     taxpayerId: "1234",
        //   },
        //   {
        //     entripseName: "企业2222" + time,
        //     taxpayerId: "2222",
        //   },
        //   {
        //     entripseName: "企业3333" + time,
        //     taxpayerId: "3333",
        //   },
        // ];
        // return;
        loading.value = true;
        const { data } = await props.remoteMethod({
          [props.searchKey]: value
        });
        options.value = data ? data.list || data : [];
        loading.value = false;
      }
    };
  }
});
