/*
 * @Description:
 * @Date: 2023-03-22 15:00:55
 */
import { useTableColumn } from 'xrk-components';
import { BaseButton, createDialog } from 'xrk-components';
import OrderMgmtProjectDetail from '../components/order-mgmt-project-detail.vue';
import OrderMgmtPriceTotal from '../components/order-mgmt-price-total.vue';
export const index = useTableColumn({
  type: 'index',
  label: '序号',
  align: 'center'
});
export const batchOrderNoCol = useTableColumn({
  label: '订单编号',
  prop: 'orderNo'
});
export const createDtCol = useTableColumn({
  label: '订单生成时间',
  prop: '_createDt'
});
export const orderPriceCol = useTableColumn({
  label: '订单总金额',
  prop: '_amount',
  render({ row, cellValue }) {
    return (
      <BaseButton
        type="text"
        onClick={() => {
          createDialog({
            width: '1050px',
            title: '订单总金额',
            showClose: true,
            showFooter: false,
            contentRender: done => (
              <OrderMgmtPriceTotal row={row} done={done}></OrderMgmtPriceTotal>
            )
          });
        }}
      >
        {cellValue}
      </BaseButton>
    );
  }
});
export const projectDetailCol = useTableColumn({
  label: '项目明细',
  render({ row }) {
    return (
      <BaseButton
        type="text"
        onClick={() => {
          createDialog({
            width: '650px',
            title: '项目明细',
            showClose: true,
            showFooter: false,
            contentRender: done => (
              <OrderMgmtProjectDetail
                row={row}
                done={done}
              ></OrderMgmtProjectDetail>
            )
          });
        }}
      >
        查看
      </BaseButton>
    );
  }
});
export const payStatus = useTableColumn({
  label: '支付状态',
  prop: '_payStatus'
});
export const projectNameCol = useTableColumn({
  label: '项目名称',
  prop: 'projectName'
});
export const settlementNoCol = useTableColumn({
  label: '结算单号',
  prop: 'settlementNo'
});
export const createDateCol = useTableColumn({
  label: '提交日期',
  prop: '_createDt'
});
export const totalPriceCol = useTableColumn({
  label: '终端结算总金额',
  prop: '_projectUserAmount'
});
export const fullNameCol = useTableColumn({
  label: '姓名',
  prop: 'fullName'
});
export const phoneCol = useTableColumn({
  label: '手机号',
  prop: 'phone'
});
export const taskSumCol = useTableColumn({
  label: '结算任务量',
  prop: 'taskSum'
});
export const taskPriceCol = useTableColumn({
  label: '终端结算单价',
  prop: '_projectUserPrice'
});
export const taskPriceTotalCol = useTableColumn({
  label: '终端结算金额合计',
  prop: '_projectUserAmount',
  width: 150
});
export const projectPersonNumCol = useTableColumn({
  label: '项目结算人次',
  prop: 'userSum'
});
