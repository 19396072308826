/*
 * @Description:
 * @Date: 2023-05-15 17:41:04
 */
import { personInformationListData } from '../data-source/types';
import { money } from 'xrk-tools';
export const resultTranslatorsPersonInformation = (
  result: Result<personInformationListData>
): Promise<Result<personInformationListData>> => {
  return new Promise(resolve => {
    result.data.list.forEach(item => {
      item['_cardNo'] = item.cardNo || '-';
      item['_bankNo'] = item.bankNo || '-';
      item['_userPrice'] = money.moneyFixed(item.userPrice);
      item['_settledMoney'] = money.moneyFixed(item.settledMoney);
      item['_passingMoney'] = money.moneyFixed(item.passingMoney);
    });
    resolve(result);
  });
};
