/*
 * @Description:
 * @Date: 2023/4/28 上午11:25:21
 */
import http from '@/utils/http/axios';
import { listTranslator } from './translators';

import { ProjectList, ProjectFormDataInterface } from './types';
import { ContentTypeEnum } from '@/utils/http/axios/enums';

enum Api {
  list = '/project/list', // 项目列表
  insertOrUpdate = '/project/insertOrUpdate', // 发起项目（新增、修改）
  getQuestion = '/project/getQuestion', // 获取项目题信息（调研和拜访）
  projectGet = '/project/get', // 项目详情
  customerList = '/projectSelect/customerList', //客户企业下拉框
  productList = '/projectSelect/productList', //项目产品下拉框
  projectTypeList = '/projectSelect/projectType', //服务类型下拉框
  targetTypeList = '/projectSelect/targetType' //拜访类型下拉框
}
// 项目列表
export const list: ProjectList = async params => {
  const respond = await http.request({
    url: Api.list,
    method: 'POST',
    params
  });
  return listTranslator(respond);
};

// 发起项目（新增、修改）
export const insertOrUpdate = async (params: ProjectFormDataInterface) => {
  return await http.request(
    {
      url: Api.insertOrUpdate,
      method: 'POST',
      params,
      headers: {
        'Content-type': ContentTypeEnum.JSON
      }
    },
    {
      isParseToJson: true
    }
  );
};

// 获取项目题信息（调研和拜访）
export const getQuestion = async params => {
  return await http.request({
    url: Api.getQuestion,
    method: 'POST',
    params
  });
};
// 项目详情
export const projectGet: (params: {
  id: string | number;
  t?: number;
}) => GApiResult<ProjectFormDataInterface> = async params => {
  return await http.request({
    url: Api.projectGet,
    method: 'POST',
    params
  });
};

// 客户企业下拉框
export const customerList: (params: {
  name: string;
  pageNo?: number;
  pageSize?: number;
}) => GApiResult<{
  list: {
    name: string;
    id: number;
  }[];
}> = async params => {
  return await http.request({
    url: Api.customerList,
    method: 'POST',
    params
  });
};

// 项目产品下拉框
export const productList: (params: {
  name: string;
  pageNo?: number;
  pageSize?: number;
}) => GApiResult<{
  list: {
    name: string;
    id: number;
  }[];
}> = async params => {
  return await http.request({
    url: Api.productList,
    method: 'POST',
    params
  });
};

// 服务类型下拉框
export const projectTypeList: (params: { name?: string }) => GApiResult<
  {
    name: string;
    id: number;
  }[]
> = async params => {
  return await http.request({
    url: Api.projectTypeList,
    method: 'POST',
    params
  });
};

// 拜访类型下拉框
export const targetTypeList: (params: {
  projectType?: string | number;
}) => GApiResult<
  {
    name: string;
    id: number;
  }[]
> = async params => {
  return await http.request({
    url: Api.targetTypeList,
    method: 'POST',
    params
  });
};
