/*
 * @Description:检查用户是否需要企业认证
 * @Date: 2023/5/22 上午11:25:21
 */

import { getESignAuthUrl } from '@/common/data-source/user/apis';
import OpenNewWin from '@/utils/openNewWin';
import { createDialog, BaseButton } from 'xrk-components';
import { ElMessageBox } from 'element-plus';
import appStore from '@/store';

export const useIntEntStatus = async (isAnthIng, msg) => {
  const { logout } = appStore.useUserStoreSetup;
  createDialog({
    width: '1200px',
    title: '',
    showClose: false,
    showFooter: false,
    contentRender: () => {
      const finishSign = () => {
        ElMessageBox.confirm(
          '请在新打开的页面完成认证，认证完成请点击完成',
          '提示',
          {
            confirmButtonText: '完成',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).finally(async () => {
          logout();
        });
      };
      return (
        <div>
          <div style="margin:0px auto;width:1061px">
            <span style="display: inline-block;width: 8px;height: 18px;background: #1989fa;margin-right: 8px;"></span>
            <span style="font-size:20px;">企业认证</span>
          </div>
          <div style="width: 1061px;height: 452px;border: 1px solid #DFDFDF;margin: 0 auto;padding-top:143px;margin-top: 30px;">
            <div style="width: 122px;height: 122px;border-radius: 50%;background:#41AAED;margin: 0 auto;position: relative;">
              <div style="width:30px;height:4px;position: absolute;top:65px;left:59px;background: white;"></div>
              <div style="width:4px;height:30px;position: absolute;top:38px;left:59px;background: white;"></div>
            </div>
            <div style="text-align:center;font-size:16px;margin-top:30px;">
              {msg}
            </div>
          </div>
          <div>
            <span style="justify-content: center; display: flex; margin: 20px 10px 10px 10px">
              {!isAnthIng && (
                <BaseButton
                  style="width:350px;height:40px;"
                  onClick={async () => {
                    const { data } = await getESignAuthUrl();
                    const win = new OpenNewWin();
                    win.createWin();
                    win.setUrl(data.url);
                    finishSign();
                  }}
                >
                  前往认证
                </BaseButton>
              )}
              {!!isAnthIng && (
                <BaseButton
                  style="width:350px;height:40px;"
                  onClick={async () => {
                    logout();
                  }}
                >
                  退出登录
                </BaseButton>
              )}
            </span>
          </div>
        </div>
      );
    }
  });
};
