/*
 * @Description: 注册路由。 遍历views中的app.ts
                 meta: {
                  isLayout: true, // 布局容器。
                  noLayout: true, // 不会添加到layout路由中，例如登录。也不会匹配到动态路由生成。
                  isIndependent: true, // 独立启动，主要是在微服务中使用，只有dev环境会启用。
                  isRelation: true, // 关系型路由,只会动态匹配顶级路由，children中路由不进行匹配。
                  isDynamicContainer: true // 标识是否为自动生成的模块路由，未匹配到本地路由则会生成该模块。
                 }
 * @Date: 2021-09-15 14:35:55
 */
import appStore from '@/store';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { createRouterGuards } from './guide';
import { App, markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import layout from '@/layout/index.vue';
import { MENUS } from '@constants/system';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'layout',
    component: markRaw(layout),
    meta: { isLayout: true },
    children: []
  }
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: '/home',
  //   meta: {}
  // }
];
// const layoutRoute = routes.find(item => item.name === 'layout');
const layoutRoute = { children: [] };
const views = require.context('@/views', true, /app.js|app.ts$/);
views.keys().forEach(path => {
  const { route: curRoutes } = views(path);
  if (curRoutes) {
    curRoutes.forEach(route => {
      /*
                                          放于每个meta中判断，方便分离路由。
                                          开发时候设置这个数据后可以不用再模块配置，直接显示。提交代码记得删除
                                        */
      if (route.meta.noLayout) {
        routes.push(route);
      } else {
        layoutRoute.children.push(route);
      }
    });
  }

  // Array.prototype.push.apply(topLevel ? routes : routes[0].children, route);
});
const router = createRouter({
  //createWebHistory -- history模式
  //createWebHashHistory -- hash模式
  history: createWebHashHistory(),
  routes
});
export default router;

export function setupRouter(app: App) {
  app.use(router);
  createRouterGuards(router);
  return router;
}
export const layoutRouteChildren = layoutRoute.children;
export const createContainerRoute = routeInfo => {
  return {
    // redirect: '/home', // 未处理重定向
    component: markRaw(RouterTransition),
    children: [],
    ...routeInfo
  };
};
export const dynamicRouter = (menus, parentUrl = '') => {
  const list = menus.reduce((pre, cur, index) => {
    const { menu_code, menu_name } = cur;
    const localeRoute = layoutRouteChildren.find(r => r.name === menu_code);
    const { meta, children } = localeRoute || {};
    const path = `${parentUrl}/${menu_code}`;
    // 本地无匹配路由视为容器路由
    if (!localeRoute) {
      pre.push(
        createContainerRoute({
          path,
          name: menu_code,
          meta: {
            title: menu_name,
            isDynamicContainer: true
          }
        })
      );
    } else if (meta?.isRelation) {
      /* 
                                        关系型路由，内部包含了多个子路由（子路由不再有children），以第一个子路由为入口。
                                        该路由只需要在模块管理中注册顶级路由，匹配的name也是顶级路由的。children不需要注册。
                                      */
      if (!children?.length) {
        throw new Error('关系型路由数据错误，无children');
      }
      pre.push({
        ...{
          ...localeRoute,
          meta: {
            ...localeRoute?.meta,
            title: menu_name
          }
        },
        path,
        redirect: `${path}/${localeRoute.children[0].name}`,
        children: children.map(item => {
          return {
            ...item,
            path: `${path}/${item.name}`
          };
        })
      });
    } else {
      pre.push({
        ...{
          ...localeRoute,
          meta: {
            ...localeRoute?.meta,
            title: menu_name
          }
        },
        path
      });
    }
    if (cur.menus?.length) {
      pre[index].children = dynamicRouter(cur.menus, path);
    }
    return pre;
  }, []);
  return list;
};

export const addRouters = () => {
  // const { info } = appStore.useUserStoreSetup;
  const { setMenus } = appStore.useMenuStoreSetup;
  // addRoute允许带children添加，所以循环第一层即可
  // const _routes = dynamicRouter(info.menus);
  const _routes = dynamicRouter(MENUS); // 写死路由配置
  _routes.forEach(itemRouter => {
    router.addRoute('layout', itemRouter);
  });
  setMenus(_routes);
};
