/*
 * @Description:
 * @Author: xinzeedu@163.com
 * @Date: 2023/4/19 上午11:43:11
 */
// import store from './store';
import { route } from './src/router';

const name = 'projectList';

export { name, route };
