/*
 * @Description:
 * @Date: 2021-10-20 11:43:02
 */
import { markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import ProjectMigrationList from '../views/project-settled-list.vue';

export const route = [
  {
    path: '/project-settled',
    name: 'project-settled',
    redirect: '/project-settled/project-settled-list',
    component: markRaw(RouterTransition),
    meta: {
      title: '项目结算管理',
      isRelation: true
    },
    children: [
      {
        name: 'project-settled-list',
        meta: {
          title: '项目结算管理'
        },
        component: ProjectMigrationList
      }
    ]
  }
];
