/*
 * @Description:
 * @Date: 2023/5/16 下午14:25:21
 */
import http from '@/utils/http/axios';
import {
  signContractParams,
  signContractResult,
  autoSignParams,
  doSignContractParams,
  authSignResult,
  LoginParams
  // doSignContractResult
} from '@V/sign-contract/src/data-source/types';
import { resultTranslatorsSignContract } from '@V/sign-contract/src/data-source/translators';

enum Api {
  signList = 'sign/list', // 列表
  signAutoSign = 'sign/autoSign', // 开启/关闭 静默签
  signDoSignContract = 'sign/doSignContract', //企业签署合同
  isAuthSign = 'sign/isAuthSign', //查询是否已开启了静默签署
  authSign = 'sign/authSign', //静默签署授权
  getUserInfo = '/user/auth/getUserInfo' //获取当前登录用户信息
}
//签约及合同管理列表
export const signContractListApi: (
  params: signContractParams
) => Promise<Result<signContractResult>> = async params => {
  const respond = await http.request({
    url: Api.signList,
    method: 'POST',
    params
  });

  return resultTranslatorsSignContract(respond);
};
//是否静默签
export const signAutoSignApi: (
  params: autoSignParams
) => Promise<Result> = async params => {
  const respond = await http.request({
    url: Api.signAutoSign,
    method: 'POST',
    params
  });
  return respond;
};

//签署合同
export const doSignContractApi: (
  params: doSignContractParams
) => Promise<Result> = async params => {
  const respond = await http.request({
    url: Api.signDoSignContract,
    method: 'POST',
    params
  });
  return respond;
};

//查询是否已开启了静默签署
export const isAuthSignApi: () => GApiResult<number> = () =>
  http.request({
    url: Api.isAuthSign,
    method: 'POST'
  });
//查询是否已开启了静默签署
export const authSignApi: () => GApiResult<authSignResult> = () =>
  http.request({
    url: Api.authSign,
    method: 'POST'
  });

//获取当前登录用户信息
export const getUserInfo: () => GApiResult<LoginParams> = async () =>
  http.request({
    url: Api.getUserInfo,
    method: 'POST'
  });
