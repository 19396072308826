/*
 * @Description:
 * @Date: 2021-03-22 00:10:03
 */
import {
  isNavigationFailure,
  Router,
  RouteLocationNormalized
} from 'vue-router';
import NProgress from 'nprogress';
import appStore from '@/store';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { WHITE_LIST, TOKEN_BLACK_LIST } from '@constants/system';
import { LOGIN_PAGE_NAME } from '@constants/system';

import { addRouters } from './index';

let registerRouteFresh = true;
NProgress.configure({ showSpinner: false });

const intercept = (to: RouteLocationNormalized, next) => {
  const { info: userInfo } = storeToRefs(appStore.useUserStoreSetup);
  const { menus } = storeToRefs(appStore.useMenuStoreSetup);

  // 白名单或单独启动调试，可以直接进入页面
  if (
    WHITE_LIST.includes(to.name) ||
    (to.meta.isIndependent && process.env.NODE_ENV === 'development')
  ) {
    next();
    return;
  }
  // 可以进入登录页
  if (!userInfo.value.token && to.name === LOGIN_PAGE_NAME) {
    next();
    return;
  }
  if (!userInfo.value.token) {
    // 跳转主项目login页面
    next({
      name: LOGIN_PAGE_NAME,
      query: { redirect: router.currentRoute.value.fullPath }
    });
  } else {
    // 已登录并在登录页进行重定向，无path重定向
    if (to.name === LOGIN_PAGE_NAME || to.path === '/') {
      next({
        path: menus.value[0].path
      });
      return;
    }
    // 重定向
    if (TOKEN_BLACK_LIST.includes(to.name)) {
      next({
        name: to.name
      });
    } else {
      next();
    }
  }
};
export async function createRouterGuards(router: Router) {
  router.beforeEach(async (to, from, next) => {
    NProgress.start();
    if (registerRouteFresh) {
      addRouters();
      intercept(to, parameters => {
        const _to = parameters || to;
        next({ ..._to, replace: true });
      });
      registerRouteFresh = false;
    } else {
      intercept(to, next);
    }
  });

  router.afterEach((to, from, failure) => {
    if (isNavigationFailure(failure)) {
      console.log('failed navigation', failure);
    }
    const scrollDom = document.querySelector(
      '#right-scroll-block .el-scrollbar__wrap'
    );
    if (scrollDom && scrollDom?.scrollTop && !to.meta.keepAlive) {
      scrollDom.scrollTo(0, 0);
    }

    // 解决跳转时滚动条高度跟随问题
    const main = document.querySelector('.layout__main');
    if (main && main.scrollTop) {
      main.scrollTop = 0;
    }
    NProgress.done();
  });

  router.onError(error => {
    console.log(error, '路由错误');
  });
}
