/*
 * @Description: 审核当条数据
 * @Date: 2021-10-28 18:10:31
 */

import auditNoPass from './audit-no-pass';
import { ElMessageBox, ElNotification } from 'element-plus';
import { audit, detailAudit } from '../data-source/apis';
import { AuditParams } from '../data-source/types';
/**
 * @description: 单条记录审核
 * @param {function} beforeGetList 点击确认 ，准备调接口之前
 * @param {function} afterGetList 取消或者接口请求完毕调用
 * @param {object} params 请求参数 包括审核状态operateAuditStatus
 * @param {function} getList 父级列表方法
 * @param {boolean} inDetail 详情内审核
 * @return {promise} Promise.resolve(pdata)
 */
export default async function ({
  params = {} as AuditParams,
  beforeGetList = () => {
    return;
  },
  afterGetList = () => {
    return;
  },
  getList,
  inDetail = false
}) {
  const auditFunc = inDetail ? detailAudit : audit;
  const { operateAuditStatus } = params;
  let notificationMessage = '操作成功';
  try {
    // 通过
    if (+operateAuditStatus === 2) {
      await ElMessageBox.confirm(`<p>是否确认通过此任务记录，请谨慎操作</p>`, {
        dangerouslyUseHTMLString: true
      });
      notificationMessage = '通过成功';
      beforeGetList();
    }
    // 不通过
    if (+operateAuditStatus === 3) {
      const rejectResp: { auditOpinion?: string } = await auditNoPass();
      params.auditOpinion = rejectResp.auditOpinion;
      notificationMessage = '不通过成功';
      beforeGetList();
    }
  } catch (err) {
    return;
  }

  const { data } = await auditFunc(params);
  ElNotification({
    message: notificationMessage,
    type: 'success'
  });
  getList && (await getList());
  afterGetList();
  return Promise.resolve(data);
}
