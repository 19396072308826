/*
 * @Description:
 * @Date: 2021-03-25 09:21:22
 */
import { ComponentInternalInstance, Ref } from 'vue';
import { IChangeSearch, IFormData } from '../types';

interface UseFormActionContext {
  emit: (event: 'submitSearch', ...args: any[]) => void;
  getFilteValues?: any;
  formData: IFormData;
  filteData: IFormData;
  currentInstance: ComponentInternalInstance | null;
  baseSearchRef: Ref<any>;
}
export default ({
  emit,
  formData,
  filteData,
  currentInstance,
  baseSearchRef
}: UseFormActionContext) => {
  // 事件回调
  const changeCallback: IChangeSearch = ({
    currentVal,
    item,
    index,
    formData
  }) => {
    if (item.event) {
      item.event({ currentVal, item, index, currentInstance, formData });
    }
  };
  // 过滤掉数据为空字符串
  const getFilteValues = () => {
    const value: IFormData = {};
    Object.keys(formData).forEach(key => {
      if (
        formData[key] !== '' &&
        formData[key] !== undefined &&
        formData[key] != null
      ) {
        value[key] = formData[key];
      }
    });
    filteData = value;
    return filteData;
  };
  // 清空输入
  const clearValues = () => {
    baseSearchRef.value.resetFields();
  };
  const submitSearch = () => {
    return new Promise((resolve, reject) => {
      baseSearchRef.value.validate((v: boolean) => {
        if (v) {
          const values = getFilteValues();
          emit('submitSearch', values);
          resolve(values);
        } else {
          reject('validate fail');
        }
      });
    });
  };
  return {
    changeCallback,
    submitSearch,
    getFilteValues,
    clearValues
  };
};
