/*
 * @Description:下载中心的搜索字段
 * @Date: 2023-04-21 13:51:38
 *
 */

import { useInputColumn, useSelectColumn } from 'xrk-components';
import {
  ENTERPTISE_SIGNINGS_STATUS,
  PERSON_STATUS,
  CONTRACT_SIGING_STATUS
} from '../constants/index';
export const fullNameSearchs = useInputColumn({
  prop: 'fullName',
  label: '姓名:',
  type: 'input',
  config: {
    placeholder: '请输入姓名',
    clearable: true,
    maxlength: 30
  }
});
export const phoneSearchs = useInputColumn({
  prop: 'phone',
  label: '手机号:',
  type: 'input',
  config: {
    type: 'tel',
    placeholder: '请输入手机号',
    clearable: true,
    maxlength: 11
  }
});
export const firstPartySignStatusSearchs = useSelectColumn({
  prop: 'firstPartySignStatus',
  label: '企业签约状态:',
  config: {
    placeholder: '请选择企业签约状态',
    clearable: true,
    options: Array.from(ENTERPTISE_SIGNINGS_STATUS).map(i => {
      return {
        label: i[1].label,
        value: i[1].value
      };
    })
  }
});
export const secondPartySignStatusSearchs = useSelectColumn({
  prop: 'secondPartySignStatus',
  label: '自然人签约状态:',
  config: {
    placeholder: '请选择自然人签约状态',
    clearable: true,
    options: Array.from(PERSON_STATUS).map(i => {
      return {
        label: i[1].label,
        value: i[1].value
      };
    })
  }
});
export const signStatusSearchs = useSelectColumn({
  prop: 'signStatus',
  label: '合同签约状态:',
  config: {
    placeholder: '请选择合同签约状态',
    clearable: true,
    options: Array.from(CONTRACT_SIGING_STATUS).map(i => {
      return {
        label: i[1].label,
        value: i[1].value
      };
    })
  }
});
