/*
 * @Description:
 * @Date: 2023/4/20 下午3:41:37
 */

//实名认证 状态
export const AUTH_STATUS = {
  0: { label: '未认证', value: 0 },
  1: { label: '认证中', value: 1 },
  2: { label: '认证成功', value: 2 },
  3: { label: '认证失败', value: 3 }
};

//入驻状态 状态
export const CONFIRM_STATUS = {
  1: { label: '未入驻', value: 1 },
  2: { label: '已入驻', value: 2 }
};
