/*
 * @Description: 项目配置
 * @Date: 2021-03-22 00:21:31
 */

export const TOKEN_KEY = 'Cso-Token';

export const REQUEST_PATH = '/rest/dlfb/asp';

export const FILE_VIEW_URL =
  '//oss.hiweeke.com/WEB/static-html/file-view/index.html';

export const DOMAIN = (() => {
  if (process.env.NODE_ENV !== 'development') {
    return window.location.hostname.split('.').slice(-2).join('.');
  }
  return window.location.hostname;
})();
