/*
 * @Description: 入参验证器
 * @Date: 2021-10-13 11:13:48
 */

import { createOutput } from './output';

import type { Output, Status, Data, Message } from './output';

const VALIDATOR_TYPE = 'validator';

enum OutputEnum {
  SUCCESS = 1,
  ERROR = 0,
  SUCCESS_MESSAGE = '验证成功',
  ERROR_MESSAGE = '验证失败'
}

// 校验返回结构
interface ValidateReturn extends Output {
  type: 'validator';
}

// 错误值
type RejectValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | ((val: any) => boolean); // true 验证失败

// 校验器入参
type Validator = {
  (rejectValue?: RejectValue, method?: (val: any) => ValidateReturn): any;
};

type AnyObject = {
  [key: string]: any;
};

/**
 * @description: 生成输入格式
 * @param {number} 状态
 * @param {any} data
 * @param {string} 消息
 * @return {object} 输入格式
 */
const createValidateOutput = (
  status: Status,
  data: Data = null,
  message: Message = ''
): ValidateReturn => {
  return createOutput({
    status,
    type: VALIDATOR_TYPE,
    message,
    data
  }) as ValidateReturn;
};

/**
 * @description: 默认校验方法
 * @param {any} 校验参数
 * @return {object} {status,message}
 */
const defaultMethod = (
  rejectValue: RejectValue,
  data: AnyObject
): ValidateReturn => {
  if (!data) {
    return createValidateOutput(
      OutputEnum.SUCCESS,
      '',
      OutputEnum.SUCCESS_MESSAGE
    );
  }
  if (Object.prototype.toString.call(data) === '[object Object]') {
    const keys = Object.keys(data);
    for (const key of keys) {
      if (
        Object.prototype.toString.call(rejectValue) === '[object Function]' &&
        (rejectValue as (val: any) => boolean)(data[key])
      ) {
        return createValidateOutput(
          OutputEnum.ERROR,
          key,
          OutputEnum.ERROR_MESSAGE
        );
      }
      if (
        Object.prototype.toString.call(rejectValue) === '[object String]' &&
        data[key] === rejectValue
      ) {
        return createValidateOutput(
          OutputEnum.ERROR,
          key,
          OutputEnum.ERROR_MESSAGE
        );
      }
    }
    return createValidateOutput(
      OutputEnum.SUCCESS,
      '',
      OutputEnum.SUCCESS_MESSAGE
    );
  }
  return createValidateOutput(0, '', 'need Object');
};

/**
 * @description: 默认校验失败方法
 * @param {object} data log参数内容
 * @return {void}
 */
// const defaultMethodFail = data => {
//   console.error('校验失败: ', data);
// };

/**
 * @description: 校验方法
 * @param {function | string | number | boolean | null | undefined } rejectValue
 * @param {function} method
 * @return {object} descriptor
 */
const validator: Validator = (rejectValue = '', method) => {
  return (target: any, name: string, descriptor: any) => {
    const oldValue = descriptor.value;
    descriptor.value = function (requestData: any) {
      // 验证处理
      const validateOutputs = method
        ? method(requestData)
        : defaultMethod(rejectValue, requestData);
      if (validateOutputs.status === 0) {
        return Promise.reject(validateOutputs);
      }
      return oldValue.apply(this, arguments);
    };
    return descriptor;
  };
};

export default validator;
