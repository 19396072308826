/*
 * @Description: 数据的准备ts
 * @Date: 2023-04-21 14:50:07
 */
import { date } from 'xrk-tools';
// 声明 且 初始化 默认日期 值
export const DATEPICKER_DEFAULT_VALUE = [
  date.dateFormat(new Date().setMonth(new Date().getMonth() - 1), 'Y-M-D'),
  date.dateFormat(new Date(), 'Y-M-D')
];
//文件生成及进度
export const FILE_STATUS = new Map([
  [0, { label: '生成中', value: 0 }],
  [1, { label: '生成中', value: 1 }],
  [2, { label: '完成', value: 2 }],
  [3, { label: '失败', value: 3 }]
]);
