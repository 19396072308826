/*
 * @Description:
 * @Date: 2023-05-18 15:07:59
 */

import { QuestionInfo } from '../data-source/types';
import { TopicsEditPageName } from '../router';
import { getQuestion } from '../data-source/apis';
import router from '@/router';
import { sessionStorage } from '@/utils/storage';
import {
  MAKE_SCENE_LOCATION_TYPE,
  MAKE_SCENE_TARGET_TYPE,
  MAKE_SCENE_TIME_TYPE
} from '@/common/constants/configurable';
import { ProjectType } from '@/common/types/project';
import { createDialog } from 'xrk-components';
import { ref } from 'vue';

const questionnaireKey = 'local_questionnaire_info';
export const { useQuestionnaire } = (() => {
  let questionnaireInfo: { [key: number]: Partial<QuestionInfo> } = {};
  return {
    useQuestionnaire: () => {
      const getKey = (projectId: number, projectType: ProjectType) =>
        `${projectId}-${projectType}`;
      const getQuestionnaireInfoForSave = (
        projectId: number,
        projectType: ProjectType
      ): undefined | QuestionInfo => {
        if (!getQuestionnaireInfo(projectId, projectType)) return void 0;
        const info = JSON.parse(
          JSON.stringify(getQuestionnaireInfo(projectId, projectType) || {})
        ) as QuestionInfo;
        const config = {
          [MAKE_SCENE_TARGET_TYPE]: {
            setKey: 'isSetTarget',
            titleKey: 'targetTitle',
            typeKey: 'targetType',
            remarkKey: 'targetRemark'
          },
          [MAKE_SCENE_TIME_TYPE]: {
            setKey: 'isSetTime',
            titleKey: 'timeTitle',
            typeKey: 'timeType',
            remarkKey: 'timeRemark'
          },
          [MAKE_SCENE_LOCATION_TYPE]: {
            setKey: 'isSetLocation',
            titleKey: 'locationTitle',
            remarkKey: 'locationRemark'
          }
        };
        const resInfo = {
          title: info.title,
          remark: info.remark,
          banner: info.banner
        };
        resInfo['isSetLocation'] = 0;
        resInfo['isSetTarget'] = 0;
        resInfo['isSetTime'] = 0;
        const baseQuestionnaires = info?.['topics']?.reduce((pre, cur) => {
          if (config[cur._rootType]) {
            const { setKey, titleKey, typeKey, remarkKey } =
              config[cur._rootType] || {};
            setKey && (resInfo[setKey] = 1);
            titleKey && (resInfo[titleKey] = cur.title);
            typeKey && (resInfo[typeKey] = cur.type);
            remarkKey && (resInfo[remarkKey] = cur.remark);
          } else {
            pre.push({
              title: cur.title,
              remark: cur.remark,
              type: cur._rootType,
              options: cur.options?.map(i => ({ topicOption: i.topicOption })),
              rule: {
                isMustFill: cur.rule.isMustFill ? 1 : 0,
                imageLeastNumber: cur.rule.imageLeastNumber || 1,
                imageMostNumber: cur.rule.imageMostNumber || 10,
                minNum: cur.rule.minNum || 0,
                maxNum: cur.rule.maxNum || 0,
                numberType: cur.rule.numberType || 0
              }
            });
          }
          return pre;
        }, [] as QuestionInfo['topics']);
        resInfo['topics'] = baseQuestionnaires;
        return resInfo as QuestionInfo;
      };

      const getQuestionnaireInfo = (
        projectId: number,
        projectType: ProjectType
      ) => questionnaireInfo?.[getKey(projectId, projectType)] as QuestionInfo;

      const getQuestionnaireInfoFromServer = async (
        projectId: number
      ): Promise<QuestionInfo> => {
        const res = await getQuestion({ projectId });
        return res;
      };

      const setQuestionnaireInfo = (
        projectId: number,
        projectType: ProjectType,
        info: Partial<QuestionInfo>
      ) => {
        questionnaireInfo[getKey(projectId, projectType)] = {
          ...(questionnaireInfo[projectId] || {}),
          ...info
        };
        console.log(info);
        sessionStorage.set(questionnaireKey, questionnaireInfo);
      };

      const clearQustionnaireInfo = () => {
        questionnaireInfo = {};
        sessionStorage.remove(questionnaireKey);
      };

      const toQuestionnaireEditPage = async (
        projectId: number,
        projectType: ProjectType
      ) => {
        if (projectId > -1 && !questionnaireInfo?.[projectId]) {
          const res = await getQuestionnaireInfoFromServer(projectId);
          setQuestionnaireInfo(projectId, projectType, res);
        }
        router.push({
          name: TopicsEditPageName,
          query: { projectId, projectType }
        });
      };

      const previewQuestionnaire = (
        info: Partial<QuestionInfo>,
        projectType: ProjectType
      ) => {
        const path = {
          1: '/views/task/src/views/task-detail-questionnaire/task-detail-questionnaire',
          2: '/views/task/src/views/task-detail-makescene/task-detail-makescene'
        }[projectType];
        const iframeRef = ref<HTMLIFrameElement>(null);
        console.log(info, 'infoinfo');

        createDialog({
          width: 'min-content',
          title: '预览',
          contentRender: () => (
            <iframe
              ref={iframeRef}
              style={{
                width: '375px',
                border: 'none',
                height: '600px',
                boxShadow: '0 0 10px #dcdcdc',
                borderRadius: '8px'
              }}
              src={`${process.env.VUE_APP_WEBVIEW}${process.env.VUE_APP_WEBVIEW_TASK_PATH}${path}?preview=1`}
              onLoad={() => {
                iframeRef.value.contentWindow.postMessage(
                  JSON.stringify({ type: 'preview', data: info }),
                  '*'
                );
              }}
            ></iframe>
          ),
          showFooter: false,
          showClose: true
        });
      };

      if (sessionStorage.get(questionnaireKey)) {
        questionnaireInfo = sessionStorage.get(questionnaireKey);
      }

      return {
        toQuestionnaireEditPage,
        getQuestionnaireInfo,
        getQuestionnaireInfoFromServer,
        setQuestionnaireInfo,
        clearQustionnaireInfo,
        previewQuestionnaire,
        getQuestionnaireInfoForSave
      };
    }
  };
})();
