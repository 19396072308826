/*
 * @Description:
 * @Date: 2023-4-21 13:29:02
 */
import { markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import signContractList from '../views/sign-contract-list.vue';

export const route = [
  {
    path: '/sign-contract',
    name: 'sign-contract',
    redirect: '/sign-contract/sign-contract-list',
    component: markRaw(RouterTransition),
    meta: {
      title: '签约及合同管理',
      isRelation: true
    },
    children: [
      {
        name: 'sign-contract-list',
        meta: {
          title: '签约及合同管理'
        },
        component: signContractList
      }
    ]
  }
];
