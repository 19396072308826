/*
 * @Description:
 * @Date: 2023/4/27 下午6:50:45
 */
//结算单签署 0 未签署  1 签署中  2 签署成功  3 签署失败
export const SIGN_STATUS = {
  0: { label: '未签署', value: 0 },
  1: { label: '签署中', value: 1 },
  2: { label: '已签署', value: 2 },
  3: { label: '签署失败', value: 3 }
};
