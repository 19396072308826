/*
 * @Description:
 * @Date: 2023/4/19 上午11:43:11
 */
// 首页项目执行状态
export const IS_COMPLETE_MAPPING = {
  0: { label: '执行中项目', value: 0 },
  1: { label: '已完结项目', value: 1 }
};
//项目类型 1调研 2拜访
export const PROJEC_TTYP = new Map([
  [1, { label: '调研', value: 1 }],
  [2, { label: '拜访', value: 2 }]
]);
// 背景需求 市场人员，医生
export const PROJECT_BACKGROUND = {
  1: { label: '市场人员', value: 1 },
  2: { label: '医生', value: 2 }
};
