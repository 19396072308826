/*
 * @Description:时间日期禁用工具
 * @Date: 2023-06-01 11:39:13
 */
export const getMonthAgo = (ago: number, date?: Date): Date => {
  const _date = date || new Date();
  const nowYear = _date.getFullYear();
  const nowMonth = _date.getMonth() + 1;
  const nowDay = _date.getDate();
  const monthAgo = ago % 12;
  const yearAgo = parseInt(ago / 12) + (monthAgo >= nowMonth ? 1 : 0);
  return new Date(
    `${nowYear - yearAgo}-${
      nowMonth - monthAgo < 0
        ? 12 - monthAgo
        : nowMonth + (monthAgo > nowMonth ? monthAgo : 0 - monthAgo) || 12
    }-${nowDay}`
  );
};
export const getMonthAfter = (after: number, date?: Date): Date => {
  const _date = date || new Date();
  const nowYear = _date.getFullYear();
  const nowMonth = _date.getMonth() + 1;
  const nowDay = _date.getDate();

  const monthfAter = (nowMonth + after) % 12 || 12;
  const yearAfter =
    nowMonth + after === 12
      ? nowYear
      : parseInt((nowMonth + after) / 12) + nowYear;
  return new Date(`${yearAfter}-${monthfAter}-${nowDay}`);
};
export const dateRangePickerDisabled = (
  monthCount: number,
  date: Date,
  startDt: Date
): boolean => {
  if (!startDt) {
    return false;
  }
  const startDate = getMonthAgo(monthCount, startDt);
  const endDate = getMonthAfter(monthCount, startDt);
  return (
    date.getTime() < startDate.getTime() || date.getTime() > endDate.getTime()
  );
};
