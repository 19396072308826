/* eslint-disable no-async-promise-executor */
import { ElLoading as Loading, ElMessage as Message } from 'element-plus';
import { download } from 'xrk-tools';
import { TOKEN_KEY } from '@/config';

export const downloadFile = async (
  url,
  useResponseFileName?: boolean,
  fileName?: string,
  method?: any
) => {
  return new Promise(async (resolve, reject) => {
    const loading = Loading.service({ fullscreen: true, text: '下载中...' });
    try {
      const res = await download.downloadFile(url, {
        fileName: fileName,
        useResponseFileName: useResponseFileName,
        tokenName: TOKEN_KEY,
        requestTokenName: TOKEN_KEY,
        method: method || 'get'
      });
      try {
        if (res.status == 0) {
          Message.error(res.message);
        }
      } catch (error) {
        error;
      }
      try {
        if (typeof res === 'object') {
          resolve(res);
        } else {
          Message.error(res);
        }
      } catch (error) {
        Message.error(res);
      }
    } catch (e) {
      Message.error('下载失败');
      reject();
    }
    loading.close();
  });
};
