/*
 * @Description:
 * @Date: 2023/4/20 下午3:41:37
 */
import http from '@/utils/http/axios';
import { PeopleListTranslator } from './translators';
import { REQUEST_PATH } from '@/config';

import {
  AddSeparatelyParams,
  PeopleList,
  OperateStatusParams,
  ListExcelParams,
  ImportTaskPlanParams,
  DelTaskPlanParams,
  ImportExcelErrorListParams,
  ImportExcelErrorListData
} from './types';
import QueryString from 'qs';
enum Api {
  getList = '/people/list',
  addSeparately = '/people/addSeparately', // 单独添加
  operateStatus = '/people/operateStatus', //"停用/冻结操作"
  listExcel = '/people/listExcel', //名单下载
  importPersonnel = '/people/importPersonnel', //导入人员
  importExcelErrorList = '/people/importExcelErrorList', //采购- 导入采购量，返回缓存中错误的数据展示
  importExcelErrorDownLod = '/people/importExcelErrorDownLod', //采购-下载错误数据excel
  importTaskPlan = '/people/importTaskPlan', //采购-导入采购任务量
  delTaskPlan = '/people/delTaskPlan', //采购-采购任务量清零
  projectPersonTemp = 'https://hwk-date.oss-cn-beijing.aliyuncs.com/WEB/template/导入人员模板.xlsx', //项目人员通用模板下载
  procurementPersonnel = 'https://hwk-date.oss-cn-beijing.aliyuncs.com/WEB/template/采购量人员模板.xlsx', //采购量人员模板下载
  downLodTaskPlanExcel = '/people/downLodTaskPlanExcel' // 采购-剩余采购量excel下载
}

// 人员列表
export const getList: PeopleList = async params => {
  const respond = await http.request({
    url: Api.getList,
    method: 'POST',
    params
  });
  return PeopleListTranslator(respond);
};

// 单独添加
export const addSeparately: (
  params: AddSeparatelyParams
) => GApiResult<Result> = params => {
  return http.request({
    url: Api.addSeparately,
    method: 'POST',
    params
  });
};

// 停用/冻结操作
export const operateStatus: (
  params: OperateStatusParams
) => GApiResult<Result> = async params => {
  const respond = await http.request({
    url: Api.operateStatus,
    method: 'POST',
    params
  });
  return respond;
};

// 名单下载
export const listExcel = (params: ListExcelParams) => {
  return `${REQUEST_PATH}${Api.listExcel}?${QueryString.stringify(params)}`;
};

// 导入人员
export const importPersonnel: (params) => Promise<Result> = async params => {
  const respond = await http.uploadFile(
    {
      url: Api.importPersonnel,
      method: 'POST'
    },
    params
  );
  return respond as unknown as Result;
};

// 采购- 导入采购量，返回缓存中错误的数据展示
export const importExcelErrorList: (
  params: ImportExcelErrorListParams
) => GApiResult<ImportExcelErrorListData> = async params => {
  const respond = await http.request({
    url: Api.importExcelErrorList,
    method: 'POST',
    params
  });
  return respond;
};

// 采购-下载错误数据excel
export const importExcelErrorDownLod = (params: {
  projectId: number;
  redisKey: string;
}) => {
  return `${REQUEST_PATH}${Api.importExcelErrorDownLod}?${QueryString.stringify(
    params
  )}`;
};

// 采购-导入采购任务量
export const importTaskPlan: (params: {
  data: ImportTaskPlanParams;
}) => GApiResult<{
  redisKey?: 'string';
}> = async params => {
  const respond = await http.uploadFile(
    {
      url: Api.importTaskPlan,
      method: 'POST'
    },
    params as any
  );
  return respond as unknown as Result;
};

// 采购-采购任务量清零
export const delTaskPlan: (
  params: DelTaskPlanParams
) => GApiResult<Result> = async params => {
  return await http.request({
    url: Api.delTaskPlan,
    method: 'POST',
    params
  });
};

// 采购-下载数据模板 采购人员&导入人员
export const personnelTemp = (params: {
  type: 'projectPersonTemp' | 'procurementPersonnel';
}) => {
  return Api[params.type];
};

export const downLodTaskPlanExcel = (params: { projectId: number }) => {
  return `${REQUEST_PATH}${Api.downLodTaskPlanExcel}?${QueryString.stringify(
    params
  )}`;
};
