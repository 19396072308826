import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout_toolbar = _resolveComponent("layout-toolbar")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_LayoutMenuHorizontal = _resolveComponent("LayoutMenuHorizontal")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_LayoutMenuVertical = _resolveComponent("LayoutMenuVertical")!
  const _component_layout_breadcrumb = _resolveComponent("layout-breadcrumb")!
  const _component_router_transition = _resolveComponent("router-transition")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "layout" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_header, { class: "layout__header" }, {
            default: _withCtx(() => [
              _createVNode(_component_layout_toolbar)
            ]),
            _: 1
          }),
          (_ctx.showHorizontal)
            ? (_openBlock(), _createBlock(_component_el_aside, {
                key: 0,
                width: "100%"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_LayoutMenuHorizontal)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_container, null, {
            default: _withCtx(() => [
              (_ctx.showVertival)
                ? (_openBlock(), _createBlock(_component_el_aside, {
                    key: 0,
                    width: "200px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LayoutMenuVertical)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_el_main, { class: "layout__main" }, {
                default: _withCtx(() => [
                  (!_ctx.showHorizontal)
                    ? (_openBlock(), _createBlock(_component_layout_breadcrumb, { key: 0 }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_router_transition)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}