/*
 * @Description:
 * @Date: 2023-03-22 15:00:55
 */
import { useTableColumn } from 'xrk-components';

export const industryFenpeiCodeCol = useTableColumn({
  label: '结算单号',
  prop: 'settlementNo'
});
export const projectNameCol = useTableColumn({
  label: '项目名称',
  prop: 'projectName'
});
export const projectSubmitCol = useTableColumn({
  label: '提交日期',
  prop: '_createDt'
});
export const payPrice = useTableColumn({
  label: '终端结算总金额',
  prop: '_projectUserAmount'
});
export const payStatus = useTableColumn({
  label: '支付状态',
  prop: '_payStatus'
});
export const orderNoCol = useTableColumn({
  label: '关联订单编号',
  prop: '_orderNo'
});
export const orderCreateDtCol = useTableColumn({
  label: '订单生成时间',
  prop: '_orderCreateDt'
});
export const orderPayDtCol = useTableColumn({
  label: '订单支付时间',
  prop: '_orderPayDt'
});
export const index = useTableColumn({
  type: 'index',
  label: '序号',
  align: 'center'
});
export const fullNameCol = useTableColumn({
  label: '姓名',
  prop: 'fullName'
});
export const phoneCol = useTableColumn({
  label: '手机号',
  prop: 'phone'
});
export const taskSumCol = useTableColumn({
  label: '结算任务量',
  prop: 'taskSum'
});
export const taskPriceCol = useTableColumn({
  label: '终端结算单价',
  prop: '_projectUserPrice'
});
export const taskPriceTotalCol = useTableColumn({
  label: '终端结算金额合计',
  prop: '_projectUserAmount'
});
