/*
 * @Description:
 * @Date: 2023-4-25 13:19:02
 */
import { markRaw } from 'vue';
import { RouterTransition } from '@/components/transition';
import appBannerList from '../views/app-banner-list.vue';

export const route = [
  {
    path: '/app-banner',
    name: 'app-banner',
    redirect: '/app-banner/app-banner-list',
    component: markRaw(RouterTransition),
    meta: {
      title: '小程序轮播图管理',
      isRelation: true
    },
    children: [
      {
        name: 'app-banner-list',
        meta: {
          title: '小程序轮播图管理'
        },
        component: appBannerList
      }
    ]
  }
];
