/*
 * @Description:
 * @Date: 2023-03-23 13:52:04
 */
import { useDateFormat } from '@vueuse/core';
import {
  TransferListItem,
  TransferListData,
  TransferProjectInfoData,
  CheckItemData
} from './types';
import {
  FIELD_STATUS_STATUS_SUCCESS,
  FIELD_STATUS_STATUS_ERROR,
  FIELD_STATUS_STATUS_DEFAULT
} from '../constants';

// 根据字段状态排序
const SORT_MAPPING = {
  [FIELD_STATUS_STATUS_DEFAULT]: 0,
  [FIELD_STATUS_STATUS_SUCCESS]: -1,
  [FIELD_STATUS_STATUS_ERROR]: 0
};
export const transferListTranslator = (resp: Result<TransferListData>) => {
  const {
    data: { list }
  } = resp;
  list.forEach(item => {
    item._serviceName = `${item.serviceName}-${item.sonServiceName}`;
    item._transferDt = item.transferDt
      ? useDateFormat(item.transferDt, 'YYYY-MM-DD HH:mm:ss').value
      : '-';
  });
  return resp;
};
export const transferProjectInfoTranslator = (
  resp: Result<TransferProjectInfoData>
) => {
  const { data } = resp;
  (Object.values(data) as Array<TransferListItem>).forEach(item => {
    item._serviceName = `${item.serviceName}-${item.sonServiceName}`;
    item._transferDt = item.transferDt
      ? useDateFormat(item.transferDt, 'YYYY-MM-DD HH:mm:ss').value
      : '-';
  });

  return resp;
};

// 结构重构，解决对象遍历乱序
export const formCheckTranslator = (resp: Result<CheckItemData>) => {
  const { data } = resp;
  Object.keys(data).forEach(k => {
    // status按1 2 0 排序展示
    data[k] = Object.keys(data[k])
      .sort(key => {
        return SORT_MAPPING[data[k][key].status];
      })
      .map(item => data[k][item]);
  });
  return resp;
};
