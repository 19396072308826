/*
 * @Description:
 * @Date: 2023/4/20 下午3:41:37
 */

import projectPersonnel from '../views/project-personnel-list.vue';

export const route = [
  {
    name: 'project-personnel',
    component: projectPersonnel,
    meta: {
      title: 'project-personnel',
      name: 'project-personnel',
      keepAlive: false
    }
  }
];
