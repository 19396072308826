/*
 * @Description:
 * @Date: 2023-04-21 17:41:04
 */
import { taskDownloadListData } from '../data-source/types';
import { FILE_STATUS } from '../constants/index';
import { date } from 'xrk-tools';
export const resultTranslatorsDownload = (
  result: Result<taskDownloadListData>
): Promise<Result<taskDownloadListData>> => {
  return new Promise(resolve => {
    result.data.list.forEach(item => {
      item['_status'] = Array.from(FILE_STATUS)[item.status][1].label;
      item['_downloadDt'] = date.dateFormat(item.downloadDt) || '-';
      item['_fileSize'] = +item.fileSize == 0 ? '-' : item.fileSize || '-';
    });
    resolve(result);
  });
};
